/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Callback from "components/Authentication/OAuth/Callback";
import Loader from "components/Common/Loader";
import UserContext from "context/UserContext";
import ApiService from "infrastructure/Services/ApiService";
import React, { useEffect, useContext, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert
} from "reactstrap";

export default (({ location }) => {
  const userContext = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState([]);
  const [authType, setAuthType] = useState(null);
  const [isOAuthCallback, setIsOAuthCallback] = useState(null);
  const history = useHistory();

  useEffect(() => {
    //set oauth callback:
    const code = location.search.match(/code=([^&]+)/)
    const state = location.search.match(/state=([^&]+)/)
    setIsOAuthCallback(code != null && state != null);

    const domain = location.search.match(/domain=([^&]+)/)
    if (domain && domain.length > 1){
      const loginEmailAddress = "login@" + domain[1];
      login(loginEmailAddress);

    }
  }, [])

  const handleOAuthCancel = (message) => { setIsOAuthCallback(false); setErrors([message]) }
  const login = (userParam) => {
    var usr = userParam || username;
    setLoading(true);
    ApiService.post('login', { emailAddress: usr, password })
      .then(resp => {
        if (!resp) {
          setLoading(false);

          return;
        }
        setAuthType(resp.authType);

        if (resp.authType === 'AzureAD' && resp.redirectUrl) {
          setTimeout(() => { window.location = resp.redirectUrl }, 200);
        } else {
          setLoading(false);
        }
      });
  }
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          {isOAuthCallback != null && isOAuthCallback === true && <CardBody className="px-lg-5 py-lg-5"><Callback location={location} onCancel={handleOAuthCancel} /></CardBody>}
          {isOAuthCallback != null && isOAuthCallback === false &&
            <CardBody className="px-lg-5 py-lg-5">
              {errors.length > 0 && <Alert>
                <ul>
                  {errors.map(error => <li>{error}</li>)}
                </ul>
              </Alert>}
              <div className="text-center text-muted mb-4">
                <b>Sign in to your account</b>
              </div>
              {<Form role="form">

                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input value={username} placeholder="Email" type="email" autoComplete="new-email" onKeyDown={(e) => {

                      if (e.key === 'Enter') {
                        e.preventDefault();
                        login();
                      }
                    }}
                      onChange={(e) => {

                        setUsername(e.target.value);
                      }} />
                  </InputGroup>
                </FormGroup>
                {authType === `password` && <><FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input value={password} placeholder="Password" type="password" autoComplete="new-password" onChange={(e) => setPassword(e.target.value)} />
                  </InputGroup>
                </FormGroup>

                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div>
                  <Row className="mt-3">
                    <Col xs="6">
                      <a
                        className="text-light"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <small>Forgot password?</small>
                      </a>
                    </Col>

                  </Row>
                </>}
                <div className="text-center text-right float-right">
                  <Button className="mt-4" color="primary" disabled={loading} type="button" onClick={login}>
                    {loading && <Loader className={`mr-3`} size={`xs`} color={`white`} />}
                    {!authType && <>Next <i className="fa fa-chevron-right ml-2" /></>}
                    {authType && authType !== 'Password' && <> Redirecting...</>}
                  </Button>
                </div>
              </Form>}

            </CardBody>}
        </Card>

      </Col>
    </>
  )
});
