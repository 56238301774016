import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import {
  Badge,
  Media,
  Table,
} from "reactstrap";
import DataService from "infrastructure/Services/DataService";
import UserContext from "context/UserContext";
export default ({ bookings, history }) => {
  const [periods, setPeriods] = useState(null);
  const user = useContext(UserContext);
  const getData = async () => {
    const _periods = await DataService.periods();
    setPeriods(_periods);
  }
  useEffect(() => {
    getData();
  }, []);

  return (
    <>{periods && <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th>
          </th>
          <th scope="col">Date</th>
          <th scope="col" className="text-center">Time</th>
          <th scope="col">Year</th>
          <th scope="col">Room</th>
          <th scope="col">Staff</th>
          <th scope="col">Practical Name</th>
          {user.isAdmin && <th scope="col">Technician Notes</th>}
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {bookings.sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        }).map((booking) => {
          let periodString = `${booking.startPeriodName}`

          let bookingTime = `${booking.startHour}:${booking.startMinute}`;
          if (booking.startPeriod !== booking.endPeriod) {
            periodString += ` - ${booking.endPeriodName}`
            bookingTime += ` - ${booking.endHour}:${booking.endMinute}`;
          } else {
            bookingTime += ` - ${booking.endHour}:${booking.endMinute}`;
          }

          return <tr key={booking.id} className="cursor-pointer" onClick={(e) => {
            e.preventDefault()
            var dm = new moment(booking.date).format('yyyy-MM-DD');
            history.push(`/lab/booking/${dm}/${booking.id}`);
          }}>
            <td>
              <div style={{ backgroundColor: booking.colour, width: 25, height: 25, borderRadius: 25 }}></div>
            </td>
            <td scope="row cursor-pointer">
              <Media className="align-items-center">
                <Media>
                  <span className="mb-0 text-sm">{new moment(booking.date).format("dddd, LL")}</span>
                </Media>
              </Media>
            </td>
            <td className="text-center">
              <span className="mb-0 text-sm">{periodString}</span><br />
              <span className="mb-0 text-xs text-muted">{bookingTime}</span>
            </td>
            <td>{booking.yearLevel}</td>
            <td>{booking.room}</td>
            <td>{booking.name}</td>
            <td>{booking.practicalName}</td>
            {user.isAdmin && <td>{booking.technicianNotes}</td>}
            <td className="text-right">
              <span
                className="btn-icon-only text-light cursor-pointer"
                href="#"
                role="button"
                size="sm"
                color=""
                onClick={(e) => {
                  e.preventDefault()
                  var dm = new moment(booking.date).format('yyyy-MM-DD');
                  history.push(`/lab/booking/${dm}/${booking.id}`);
                }}
              >
                <i className="fas fa-chevron-right" />
              </span>
            </td>
          </tr>;
        })}
      </tbody>
    </Table>}</>
  );
};
