/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
import ApiService from "infrastructure/Services/ApiService";
import { BrowserRouter as Router, Switch, Route, useParams } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Modal
} from "reactstrap";
import Timetable from "../../components/Timetable/Timetable";
import TimetableList from "../../components/Timetable/TimetableList";
import moment from "moment";
import DateSelector from "./DateSelector";
import CreateModal from "./Modal";
import DataService from "infrastructure/Services/DataService";
//import "./index.scss";




export default ({ history }) => {

  const { date } = useParams();
  const [view, setView] = useState("timetable");
  const [dates, setDates] = useState(null);
  const [newBooking, setNewBooking] = useState(null);
  const [bookings, setBookings] = useState([]);

  const [zoomLevel, setZoomLevel] = useState(2);
  let refreshBookings = null;

  useEffect(() => {
    onChangeDate();
    refreshBookings = setInterval(() => {onChangeDate()}, 300 * 1000);

    return () => {
      clearInterval(refreshBookings);
    }
  }, []);


  useEffect(() => {
    onChangeDate();
  }, [date]);


  const onChangeDate = () => {
    var startOfWeek = date ? new moment(date).startOf("week") : new moment().startOf("week");

    setDates({
      startDate: startOfWeek,
      endDate: new moment().endOf("week"),
    });

    getData(startOfWeek);
  }

  useEffect(() => {

  }, [bookings])

  const getData = async (date) => {
    
    var listUrl = `bookings/lab`;
    const tempPeriods = await DataService.periods();

    const selectedDate = date || dates.startDate;
    var strSelectedDate = new moment(new Date(selectedDate)).startOf("week").format('yyyy-MM-DD');

    ApiService.get(listUrl, { date: strSelectedDate })
      .then(resp => {
        for (var i = 0; i <= resp.items.length - 1; i++) {
          //get startPeriod
          var startPeriod = tempPeriods.find(m => m.id === resp.items[i].startPeriodId);
          var endPeriod = tempPeriods.find(m => m.id === resp.items[i].endPeriodId);

          resp.items[i].startPeriod = startPeriod.order;

          var startTime = startPeriod.startTime.split(':');
          resp.items[i].startHour = startTime[0];
          resp.items[i].startMinute = startTime[1];
          resp.items[i].startPeriodName = startPeriod.name;


          var endtime = endPeriod.startTime.split(':');
          resp.items[i].endHour = endtime[0];
          resp.items[i].endMinute = endtime[1];
          resp.items[i].endPeriod = endPeriod.order;
          resp.items[i].endPeriodName = endPeriod.name;
        }
        setBookings(resp.items);
      }
      );

  }

  const handleDatesChanged = (start, end) => {
    var date = new moment(start).format('yyyy-MM-DD');
    history.push(`/lab/bookings/${date}`)
    setDates({
      startDate: start,
      endDate: end,
    });
     getData(start);
  };

  const handleNewBooking = (booking) => {
    setNewBooking(booking);
  }

  const handleCloseButtonPress = (refresh) => {
    setNewBooking(null);
    if (refresh === true) getData();

  }

  const updateZoom = (n) => {
    setZoomLevel(z => {
      let newState = z + (n/2);
      while (newState > -1 && newState <= 1)
        newState += (n/2);
      return newState;
    });
  }

  return (
    <>
      {/* Page content */}
      {dates && (<Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col xs={8}>
                    <DateSelector onDateChange={handleDatesChanged} startDate={dates.startDate} type={`week`} />
                  </Col>
                  <Col xs={4}>
                    <Nav className="justify-content-end align-items-center" pills>
                      {view === "timetable" && <><NavItem>
                        <div className="d-flex justify-content-center align-items-center">
                          <a className="cursor-pointer py-3 px-2" onClick={(e) => updateZoom(1)}><i className="fas fa-search-minus fa-1x"></i></a>
                          <a className="cursor-pointer py-3 px-2" onClick={(e) => updateZoom(-1)}><i className="fas fa-search-plus fa-1x"></i></a>
                        </div>
                      </NavItem></>}
                      <NavItem>

                        <NavLink
                          className={classnames("py-2 px-3 cursor-pointer", {
                            active: view === "timetable",
                          })}
                          onClick={(e) => setView("timetable")}
                        >
                          <span className="d-none d-md-block">Timetable</span>
                          <span className="d-md-none">T</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3 cursor-pointer", {
                            active: view === "list",
                          })}
                          onClick={(e) => setView("list")}
                        >
                          <span className="d-none d-md-block">List</span>
                          <span className="d-md-none">L</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {view === "timetable" && (
                  <Timetable periods={[]} zoomLevel={zoomLevel} bookings={bookings} startDate={dates.startDate} onNewBooking={handleNewBooking} history={history}></Timetable>
                )}
                {view === "list" && (
                  <TimetableList periods={[]} bookings={bookings} history={history}></TimetableList>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      )}
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={newBooking != null}
      //toggle={() => this.toggleModal("formModal")}
      >{newBooking && <CreateModal booking={newBooking} onCloseButtonPress={handleCloseButtonPress} />}</Modal>
    </>
  );
};
