import React, { useEffect, useState, useMemo } from "react";

import { Col, Input, Row, Button, CarouselItem } from "reactstrap";
import RiskAssessmentStep from "./RiskAssessmentStep";
import DataService from "infrastructure/Services/DataService";
import Step1 from "./Step1";
import Step2 from "./Step2";
import "../Form.scss";
import FormColumn from "../../../../components/Common/FormColumn";
import Submit from "../Submit";
import AlertModal from "components/Common/AlertModal";
import ApiService from "infrastructure/Services/ApiService";
import moment from "moment";
import FormStepHeader from "../../../../components/Common/FormStepHeader";
import RiskMatrix from "components/Common/RiskMatrix";

export default ({ booking, onCloseButtonPress }) => {
  const [isValid, setIsValid] = useState(false);

  const [error, setError] = useState(false);
  const baseForm = { campusId: null };
  const [newBooking, setNewBooking] = useState({ ...booking, ...baseForm });
  const [step, setStep] = useState(1);
  const [riskTemplates, setRiskTemplates] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const getData = async () => {
    const riskData = await DataService.labBookings.riskTemplate();
    const timetable = await DataService.periods();

    //get firstPeriod 
    setRiskTemplates(riskData);
  };

  const copy = () => {
    var d = new moment(booking.bookingDate).format('yyyy-MM-DD');
    ApiService.post(`bookings/lab/${d}/${booking.id}/copy`)
      .then(() => {
        setShowModal(false);
        onCloseButtonPress(true);
      });
  }

  const updateValues = (args) => {
    setNewBooking((prevState) => {
      const newState = { ...prevState, ...args };
      return newState;
    });
  };

  const updateRiskValue = (item, args) => {
    setNewBooking((prevState) => {
      const newState = { ...prevState };
      if (!newBooking.risks) {
        newState.risks = [args];
        return newState;
      }

      const index = newState.risks.findIndex((x) => x.id === item.id);

      if (index > -1) {
        newState.risks.splice(index, 1);
      }
      newState.risks.push(args);
      return newState;
    });
  };

  const next = () => {
    setStep((prevState) => {
      return prevState + 1;
    });
  };
  const prev = () => {
    setStep((prevState) => {
      if (prevState === 1) return prevState;
      return prevState - 1;
    });
  };

  const deleteBooking = () => {
    var d = new moment(booking.bookingDate).format('yyyy-MM-DD');
    ApiService.delete(`booking/lab/${d}/${booking.id}`)
      .then(() => {
        setShowModal(false);
        onCloseButtonPress(true);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  const renderRiskSteps = () => {
    let className = `step`;

    if (step < 3) {
      className += ` next`;
    } else if (step > 3) {
      className += ` prev`;
    } else {
      className += ` current`;
    }

    return <div className={className}>
      <FormStepHeader
        sectionName={`Risk Assessment`}
        title={`Risks`}
        icon={`risk`}
      />
      <div className="customScroll pr-2 " style={{ maxHeight: 500, overflowX: `hidden`, overflowY: `scroll` }}>
        <Row className="mb-5
        "><Col xs={12} >
          <h3>Risk Matrix</h3>
          <RiskMatrix />
        </Col></Row>
        <Row>
          <Col xs={12} >
          {(riskTemplates ? riskTemplates : []).map((item, index) => (
            <RiskAssessmentStep
              key={item.id}
              riskTemplate={item}
              booking={newBooking}
              onUpdateValue={updateRiskValue}
            />
          ))}
        </Col>
        </Row>
        <Row>
          <FormColumn label={`Other`} columns={1}>
            <Input type="textarea" name="Notes" id="Notes" rows={3} value={booking.notes} onChange={e => updateValues({ notes: e.target.value })} />
          </FormColumn>
        </Row>
      </div>
    </div>;
  }

  return (
    <>
      <Row>
        <Col>
          <div className={`step-container custom-scroll`}>
            <i
              className={`fa fa-times float-right cursor-pointer modal-close-button`}
              onClick={onCloseButtonPress}
            ></i>
            <Step1
              currentStep={step}
              index={1}
              booking={newBooking}
              onUpdateValues={updateValues}
            />
            <Step2
              currentStep={step}
              index={2}
              booking={newBooking}
              onUpdateValues={updateValues}
            />


            {renderRiskSteps()}

            <Submit
            error={error}
            setError={setError}
              currentStep={step}
              index={4}
              booking={newBooking}
              onUpdateValues={updateValues}
              onBookingSaved={() => onCloseButtonPress(true)}
            />
          </div>
        </Col>
      </Row>
      {(step < 4 || error) && <Row>
        <Col>
          {step > 1 && (
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={prev}
            >
              <i className={`fa fa-chevron-left`} /> Back
            </Button>
          )}
        </Col>
        <Col>
          {(step < 4) && <Button
            color="primary"
            type="button"
            className={`float-right`}
            onClick={next}
            disabled={!newBooking || !newBooking.yearLevel || !newBooking.room || !newBooking.practicalName || !newBooking.class || (!newBooking.setsRequired || newBooking.setsRequired < 1)}
          >
            {step === 3 ? `Submit` : `Next`} <i className={`fa fa-chevron-right`} />
          </Button>}
          {booking.id && <><Button
            color="danger"
            type="button"
            className={`float-right mr-2`}
            onClick={() => setShowModal(true)}
          >
            <i className={`fa fa-trash`} /> &nbsp; Delete
          </Button>
          <Button
              color="warning"
              type="button"
              className={`float-right mr-2`}
              onClick={copy}
            >
              <i className={`fa fa-copy`} /> &nbsp; Copy
          </Button></>}
        </Col>
      </Row>}
      <AlertModal title={`Are you sure?`} content={`Are you sure you want to delete this booking?`} show={showModal} onConfirm={deleteBooking} onCancel={() => setShowModal(false)} />
    </>
  );
};
