import React from "react";
import DOMPurify from 'dompurify'
// reactstrap components
import {
  Button,

  Modal,
} from "reactstrap";

export default ({title, content, show, onConfirm, onCancel, confirmText}) => {
 

    return (
      <>

        <Modal
        size={'lg'}
          className="modal-dialog-centered"
          isOpen={show}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {title}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={onCancel}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="m-1 customScroll" style={{maxHeight: 500, overflowX: `scroll`}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content)}}>

            </div>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button color="danger" type="button" onClick={onConfirm}>
              {confirmText || `Confirm`}
            </Button>
          </div>
        </Modal>
      </>
    );
}
