
import Loader from "components/Common/Loader";
import UserContext from "context/UserContext";
import ApiService from "infrastructure/Services/ApiService";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";



export default ({ location, onCancel }) => {
    
  const userContext = useContext(UserContext);
const history = useHistory();
    useEffect(() => {
      const code = (location.search.match(/code=([^&]+)/) || [])[1];
      const state = (location.search.match(/state=([^&]+)/) || [])[1];
      const data = {code, state};

      ApiService.post("auth/oauth_token", data, () => {
        onCancel(`Your login attempt was unsuccessful. Please try again.`)
      })
      .then(json => {
        userContext.login(json);
        history.push('/lab/bookings');
    });
    }, []);
  
    return <div className={`w-100 d-flex justify-content-center`}><Loader size={`lg`} color={`primary`} /></div>;
  };