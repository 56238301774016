import UserContext from "context/UserContext";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import ApiService from "../../infrastructure/Services/ApiService";
import StorageService from "../../infrastructure/StorageService";
import { Prompt } from "react-router";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  Input,
  Nav,
  Container,
  Row,
  Col,
  Modal,
} from "reactstrap";
import { toast } from "react-toastify";

export default () => {
  const [data, setData] = useState(null);
  const [newClass, setNewClass] = useState({ name: "", yearLevel: "" });
  const [newRoom, setNewRoom] = useState("");
  const [newYear, setNewYear] = useState("");
  const [leadTime, setLeadTime] = useState(3);
  const [isDirty, setDirty] = useState(false);
  const user = useContext(UserContext);
  const history = useHistory();
  const getData = async () => {
    await ApiService.fromStorage(`lab-bookings/campuses.json`).then((json) => {
      setData(json);
    });

    await ApiService.fromStorage(`lab-bookings/leadtime.json`).then((json) => {
      setLeadTime(json.leadTime);
    });
  };
  useEffect(() => {
    if (!user.isAdmin) {
      history.location = "/dashboard";
      return;
    }
    getData();
  }, []);

  const save = async () => {
    ApiService.put("settings", { data: data, leadTime: leadTime }).then(
      async (json) => {
        toast.success("Details Saved");

        setDirty(false);
        await ApiService.fromStorage(`lab-bookings/campuses.json`).then(
          (json) => {
            StorageService.set(StorageService.keys.labBookingCampuses, json);
          }
        );

        var rand = Math.random() * 1000;
        await ApiService.fromStorage(`lab-bookings/leadtime.json`).then(
          (json) => {
            StorageService.set(StorageService.keys.leadTime, json.leadTime);
          }
        );
      }
    );
  };

  return (
    <>
      <Prompt
        when={isDirty}
        message="You have unsaved changes, are you sure you want to leave this page?"
      />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col className=" d-flex align-items-center justify-content-between">
                    <h3>Administration</h3>
                    <button className="btn btn-primary" onClick={save}>
                      Save
                    </button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={4}>
                    <h5>Lead In Time</h5>
                    <div className="rounded py-3 w-100 mb-3">
                      <div class="input-group mb-3">
                        <Input
                          type="number"
                          step="1"
                          value={leadTime}
                          className="form-control"
                          onChange={(e) => {
                            const v = parseInt(e.target.value);
                            setLeadTime(v);
                            setDirty(true);
                          }}
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2">
                            {" "}
                            days
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                {data && (
                  <>
                    {data.map((campus, ci) => (
                      <>
                        <Row>
                          {
                            <Col>
                              <h4>{campus.name}</h4>
                            </Col>
                          }
                        </Row>

                        <Row>
                          <Col>
                            <h5>Year Levels</h5>
                            {campus.yearLevels.map((year, i) => (
                              <div
                                key={`y_${i}`}
                                className="rounded d-flex align-items-center justify-content-between p-3 bg-light w-100 mb-3 float-left"
                              >
                                <span className="float-left">{year}</span>
                                <i
                                  className={`fa fa-times cursor-pointer`}
                                  onClick={(e) => {
                                    setData((prevState) => {
                                      const newState = [...prevState];
                                      newState[ci].yearLevels.splice(i, 1);
                                      return newState;
                                    });
                                    setDirty(true);
                                  }}
                                ></i>
                              </div>
                            ))}

                            <div className="rounded py-3 w-100 mb-3">
                              <Input
                                type="text"
                                value={newYear}
                                className="form-control w-100 mb-1"
                                onChange={(e) => {
                                  const v = e.target.value;
                                  setNewYear(v);
                                }}
                              />

                              <button
                                className="btn btn-primary w-100"
                                disabled={!(newYear && newYear.length > 0)}
                                onClick={() => {
                                  setData((prevState) => {
                                    const newState = [...prevState];
                                    newState[ci].yearLevels.push(newYear);
                                    return newState;
                                  });
                                  setNewYear("");
                                }}
                              >
                                Add Year Level
                              </button>
                            </div>
                          </Col>

                          <Col>
                            <h5>Classes</h5>
                            {campus.classes.map((cls, i) => (
                              <div
                                key={`c_${i}`}
                                className="rounded d-flex align-items-center justify-content-between p-3 bg-light w-100 mb-3 float-left"
                              >
                                <span className="float-left">
                                  {cls.name} - {cls.yearLevel}
                                </span>
                                <i
                                  className={`fa fa-times cursor-pointer`}
                                  onClick={(e) => {
                                    setData((prevState) => {
                                      const newState = [...prevState];
                                      newState[ci].classes.splice(i, 1);
                                      return newState;
                                    });
                                    setDirty(true);
                                  }}
                                ></i>
                              </div>
                            ))}

                            <div className="rounded py-3 w-100 mb-3">
                              <Input
                                type="text"
                                value={newClass.name}
                                className="form-control w-100 mb-1"
                                onChange={(e) => {
                                  const v = e.target.value;
                                  setNewClass((prevState) => {
                                    const newState = { ...prevState };
                                    newState.name = v;
                                    return newState;
                                  });
                                }}
                              />
                              <select
                                className="form-control w-100 mb-1"
                                onChange={(e) => {
                                  const v = e.target.value;
                                  setNewClass((prevState) => {
                                    const newState = { ...prevState };
                                    newState.yearLevel = v;
                                    return newState;
                                  });
                                }}
                              >
                                <option>Select...</option>
                                {campus.yearLevels.map((yl) => (
                                  <option>{yl}</option>
                                ))}
                              </select>
                              <button
                                className="btn btn-primary w-100"
                                disabled={
                                  !(
                                    newClass.name &&
                                    newClass.name.length > 0 &&
                                    newClass.yearLevel &&
                                    newClass.yearLevel.length > 0
                                  )
                                }
                                onClick={() => {
                                  setData((prevState) => {
                                    const newState = [...prevState];
                                    newState[ci].classes.push(newClass);
                                    return newState;
                                  });
                                  setNewClass({ name: "", yearLevel: "" });
                                  setDirty(true);
                                }}
                              >
                                Add Class
                              </button>
                            </div>
                          </Col>
                          <Col>
                            <h5>Rooms</h5>
                            {campus.rooms.map((room, i) => (
                              <div
                                key={`r_${i}`}
                                className="rounded d-flex align-items-center justify-content-between p-3 bg-light w-100 mb-3 float-left"
                              >
                                <span className="float-left">{room}</span>
                                <i
                                  className={`fa fa-times cursor-pointer`}
                                  onClick={(e) => {
                                    setData((prevState) => {
                                      const newState = [...prevState];
                                      newState[ci].rooms.splice(i, 1);
                                      return newState;
                                    });
                                    setDirty(true);
                                  }}
                                ></i>
                              </div>
                            ))}

                            <div className="rounded py-3 w-100 mb-3">
                              <Input
                                type="text"
                                value={newRoom}
                                className="form-control w-100 mb-1"
                                onChange={(e) => {
                                  const v = e.target.value;
                                  setNewRoom(v);
                                }}
                              />

                              <button
                                className="btn btn-primary w-100"
                                disabled={!(newRoom && newRoom.length > 0)}
                                onClick={() => {
                                  setData((prevState) => {
                                    const newState = [...prevState];
                                    newState[ci].rooms.push(newRoom);
                                    return newState;
                                  });
                                  setDirty(true);
                                  setNewRoom("");
                                }}
                              >
                                Add Room
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ))}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
