/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import UserContext from "context/UserContext";
import React, { useContext } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Avatar from "react-avatar";
import "./AdminNavbar.scss";
import logo from "../../assets/img/logo.svg";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  NavItem,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

export default (props) => {
  const user = useContext(UserContext);
  const history = useHistory();
  const logout = (e) => {
    user.logout();
    history.push("/auth/login");
  };

  return (
    <>
      <Navbar
        className="navbar-top main-navbar fixed-top navbar-light position-fixed bg-white shadow"
        expand="md"
        id="navbar-main"
      >
        <Container
          fluid
          className="align-items-center d-none d-md-flex justify-content-spacebetween"
        >
          <div>
            <Link to="/">
              <img alt="..." src={logo} style={{ height: 40 }} />
            </Link>
          </div>

          <Nav
            className="align-items-center d-none d-md-flex justify-content-end"
            navbar
          >
            <NavItem className="mx-3">
              <a
                href="https://westminster.sharepoint.com/:b:/s/StaffScienceResources/ER0mds1oM3xNqkFSrRv76NIBCBrFhWGilu_d7Os4uTyU1w?e=8JO70f"
                target="_blank"
                className="small text-uppercase text-white btn btn-sm btn-primary px-3 p-2"
              >
                <i className="fa fa-calendar mr-2"></i>Student Resource Slip
              </a>
            </NavItem>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <Avatar name={user.fullName} size={36} round={true} />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {user.fullName}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                  className="cursor-pointer"
                  onClick={() => history.push("/")}
                >
                  <i className="ni ni ni-calendar-grid-58" />
                  <span>Bookings</span>
                </DropdownItem>
                {user.isAdmin && (
                  <>
                    <DropdownItem
                      className="cursor-pointer"
                      onClick={() => history.push("/admin/saved-bookings")}
                    >
                      <i className="ni ni-archive-2" />
                      <span>Saved Bookings</span>
                    </DropdownItem>
                    <DropdownItem
                      className="cursor-pointer"
                      onClick={() => history.push("/admin/settings")}
                    >
                      <i className="ni ni-settings" />
                      <span>Settings</span>
                    </DropdownItem>
                    <DropdownItem
                      className="cursor-pointer"
                      onClick={() => history.push("/admin/terms")}
                    >
                      <i className="ni ni-bullet-list-67" />
                      <span>Terms</span>
                    </DropdownItem>
                  </>
                )}
                <DropdownItem className="cursor-pointer" onClick={logout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

const styles = {
  icon: { maxWidth: 40, float: `left`, display: `block`, float: `left` },
};
