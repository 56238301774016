/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Login from "views/auth/Login.js";
import LabBookings from "views/bookings/LabBookings";
import LabBooking from "views/bookings/Booking";
import Admin from "views/admin/index";
import AdminSavedBookings from "views/admin/SavedBookings";
import AdminTerms from "views/admin/terms";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/layout"
  },
  {
    path: "/lab/bookings/:date?",
    name: "Lab Bookings",
    icon: "ni ni-tv-2 text-primary",
    component: LabBookings,
    layout: "/layout"
  },  {
    path: "/lab/booking/:date/:id",
    name: "Lab Booking",
    icon: "ni ni-tv-2 text-primary",
    component: LabBooking,
    layout: "/layout"
  },{
    path: "/admin/settings",
    name: "Admin",
    icon: "ni ni-tv-2 text-primary",
    component: Admin,
    layout: "/layout"
  },{
    path: "/admin/saved-bookings",
    name: "AdminSavedBookings",
    icon: "ni ni-tv-2 text-primary",
    component: AdminSavedBookings,
    layout: "/layout"
  },{
    path: "/admin/terms",
    name: "Admin",
    icon: "ni ni-tv-2 text-primary",
    component: AdminTerms,
    layout: "/layout"
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/layout"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/layout"
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/layout"
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/layout"
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  // {
  //   path: "/oath_callback",
  //   name: "oath_callback",
  //   icon: "ni ni-key-25 text-info",
  //   component: OauthCallback,
  //   layout: "/auth"
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth"
  // }
];
export default routes;
