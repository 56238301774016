import Loader from "components/Common/Loader";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardHeader,
  Container,
  CardBody,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import "./Modal.scss";
import LabBookingForm from "./forms/LabBookings/Form";

export default (props) => {
  const [booking, setBooking] = useState(props.booking);
  const history = useHistory();

  const component = () => {
    switch (booking.type) {
      case `lab`:
        return <LabBookingForm booking={booking} onCloseButtonPress={props.onCloseButtonPress} bookingId={props.bookingId} />;
        break;
    }
  };
  return (
    <>
      <ModalBody>
        {booking && <>{component()}</>}        
      </ModalBody>
    </>
  );
};
