import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import FormColumn from "../../../../components/Common/FormColumn";
import FormStepHeader from "../../../../components/Common/FormStepHeader";
import Select from "react-select";
import Creatable from "react-select/creatable";
import DataService from "infrastructure/Services/DataService";
import Datetime from 'react-datetime';
import UserContext from "context/UserContext";
import {
  Col,
  Row,
  Input
} from "reactstrap";
import ApiService from "infrastructure/Services/ApiService";
export default ({ booking, onUpdateValues, currentStep, index }) => {
  const user = useContext(UserContext);
  const [quantities, setQuantities] = useState([]);
  const [cutOff, setCutoff] = useState(new moment().add(3, "days"));
  const [data, setData] = useState(null);
  const [periodData, setPeriodData] = useState();
  const [periods, setPeriods] = useState();
  const [isNewPractical, setIsNewPractical] = useState(false);
  const [savedBookings, setSavedBookings] = useState(null);
  const [users, setUsers] = useState(null);
  useEffect(() => {
    const newQuantities = [];
    for (var i = 1; i <= 100; i++) {
      newQuantities.push({ value: i, label: i });
    }

    setQuantities(newQuantities);
    getData();

    if (booking && booking.id) {
      booking.newBookingDate = booking.bookingDate;
    }

    if (user.isAdmin){
      ApiService.get('users')
      .then(resp => {
        setUsers(resp.items);
      })
    }
  }, []);

  useEffect(() => {

  }, [booking]);

  const calculateCutOff = async (date) => {
    if (!user.isAdmin){
      const leadTime = await DataService.leadTime();
      var newCutOff = DataService.addBusinessDays(date || new Date(), leadTime);
      return newCutOff;
    }
    return new moment().add(-1, "years");
  };

  const getData = async () => {
    const campusData = await DataService.labBookings.campuses();

    //const leadTime = await DataService.leadTime();
    var newCutOff = await calculateCutOff();
    setCutoff(newCutOff);

    const newData = { campuses: campusData };
    if (campusData.length === 1)
      onUpdateValues({ campusId: campusData[0].id, campusName: campusData[0].name });
    setData(newData);

    const p = await DataService.periods();
    setPeriodData(p);
    bindPeriods(p);


    ApiService.get(`savedbookings/lab/`)
      .then(resp => {
        setSavedBookings(resp.items);
      })
  };

  const setSavedPractical = (id) => {
    if (!id) {
      onUpdateValue("attachments", null);
      onUpdateValue("risks", null);
      onUpdateValue("notes", null);
      onUpdateValue('practicalId', null);
      onUpdateValue("technicianAssistanceRequired", null);
      return;
    }

    var savedPractical = savedBookings.find(x => x.id === id);
    if (!savedPractical) {
      onUpdateValue("attachments", null);
      onUpdateValue("risks", null);
      onUpdateValue("notes", null);
      onUpdateValue('practicalId', null);
      onUpdateValue("technicianAssistanceRequired", null);
      return;
    }

    ApiService.get(`savedbooking/lab/${id}`, { personalStore: savedPractical.isUser }).then(resp => {
      onUpdateValue("attachments", resp.attachments);
      onUpdateValue("risks", resp.risks);
      onUpdateValue("notes", resp.notes);
      onUpdateValue('practicalId', id);
      onUpdateValue('bookReference', resp.bookReference);
      onUpdateValue("technicianAssistanceRequired", resp.technicianAssistanceRequired);
    })
  }

  const bindPeriods = (pd) => {
    const dayPeriods = (pd) && (pd).filter(x => x.dayNumber == new Date(booking.newBookingDate).getDay()).map((x) => {
      return { value: x.order, label: x.name };
    });

    setPeriods(dayPeriods);
  }

  const onUpdateValue = (prop, value) => {
    const args = {};
    args[prop] = value;

    onUpdateValues(args)
  }

  const rooms = (data && data.campuses.find(x => x.id === booking.campusId)?.rooms || []).map((x) => {
    return { value: x, label: x };
  });

  const campuses = data && data.campuses.map((x) => {
    return { value: x.id, label: x.name };
  });

  const yearLevels = (data && data.campuses.find(x => x.id === booking.campusId)?.yearLevels || []).map((x) => {
    return { value: x, label: x };
  })

  const classes = (data && data.campuses.find(x => x.id === booking.campusId)?.classes || []).filter(x => x.yearLevel === booking.yearLevel).map((x) => {
    return { value: x.name, label: x.name };
  })

  const usersValue = (users || []).map((x) => {
    return { value: x.id, label: x.name };
  })

  const practicals = savedBookings && savedBookings.filter(x => x.yearLevel === booking.yearLevel).map((x) => {
    return { value: x.id, label: `${x.name} (${x.year} - ${x.userName})` };
  });

  let className = `step`;

  if (currentStep < index) {
    className += ` next`;
  } else if (currentStep > index) {
    className += ` prev`;
  } else {
    className += ` current`;
  }

  var valid = function (current) {
    if (user.isAdmin) return true;
 
    return current.isAfter(cutOff);
  };

  return (
    <div className={className}>
      <FormStepHeader
        sectionName={`Booking Details`}
        title={`New Booking Details`}
        icon={`flask`}
      />
      <div className="customScroll pr-2 " style={{ maxHeight: 500, overflowX: `hidden`, overflowY: `scroll` }}>
        {data && (
          <>
            <Row className={`d-flex align-items-center`}>
              <Col xs={12} md={6}>
                <h6 className="text-uppercase ls-1 mb-0">Date</h6>
                {(!booking.id) ?
                  <h3>{new moment(booking.bookingDate).format("dddd, LL")}</h3> :
                  <Datetime timeFormat={false}
                    initialValue={new moment(booking.newBookingDate).format("dddd, LL")}
                    value={new moment(booking.newBookingDate).format("dddd, LL")}
                    isValidDate={valid}
                    dateFormat={`dddd, LL`}
                    onChange={async e => {
                      const newBookingDate = e.toDate();
                      const newBookingDay = newBookingDate.getDay();
                      const currentBookingDay = new Date(booking.bookingDate).getDay();



                      const currentStartPeriod = periodData.find(x => x.order == booking.startPeriod && x.dayNumber == currentBookingDay);
                      const currentEndPeriod = periodData.find(x => x.order == booking.endPeriod && x.dayNumber == currentBookingDay);


                      const dayPeriods = periodData.filter(x => x.dayNumber == new Date(newBookingDate).getDay()).map((x) => {
                        return { value: x.order, label: x.name };
                      });

                      setPeriods(dayPeriods);

                      let newStartPeriod = periodData.find(x => x.dayNumber == newBookingDay && x.order == currentStartPeriod.order);

                      if (newStartPeriod == null) {
                        newStartPeriod = periodData.filter(x => x.dayNumber == newBookingDay)[0];
                      }

                      let newEndPeriod = periodData.find(x => x.dayNumber == newBookingDay && x.order == currentEndPeriod.order);
                      if (newEndPeriod == null) {
                        newEndPeriod = newStartPeriod;
                      }

                      booking.startPeriod = newStartPeriod.id;
                      booking.endPeriod = newEndPeriod.id;

                      onUpdateValues({ newBookingDate: e.toISOString() })
                      onUpdateValue("startPeriod", newStartPeriod.order)
                      onUpdateValue("startPeriodId", newStartPeriod.id)
                      onUpdateValue("endPeriod", newEndPeriod.order)
                      onUpdateValue("endPeriodId", newEndPeriod.id)


                    }} />}
              </Col>
              <Col xs={12} md={6}>
                <h6 className="text-uppercase ls-1 mb-0">Time</h6>
                {booking.id && periods ? <Row><Col>
                  <Select
                    options={periods}
                    value={periods.filter(option => option.value === booking.startPeriod)}
                    onChange={(element) => {
                      onUpdateValue("startPeriod", element.value)
                      const startPeriod = periodData.find(x => x.order == element.value && x.dayNumber == new Date(booking.newBookingDate).getDay());
                      onUpdateValue("startPeriodId", startPeriod.id)
                      if (element.value > booking.endPeriod) {
                        onUpdateValue("endPeriod", element.value)
                        const endPeriod = periodData.find(x => x.order == element.value && x.dayNumber == new Date(booking.newBookingDate).getDay());
                        onUpdateValue("endPeriodId", endPeriod.id)
                      }

                    }}
                  ></Select></Col><Col><Select
                    options={periods.filter(x => x.value >= booking.startPeriod)}
                    value={periods.filter(option => option.value === booking.endPeriod)}
                    onChange={(element) => {
                      onUpdateValue("endPeriod", element.value);
                      const endPeriod = periodData.find(x => x.order == element.value && x.dayNumber == new Date(booking.newBookingDate).getDay());
                      onUpdateValue("endPeriodId", endPeriod.id)
                    }}
                  ></Select></Col>
                </Row> : <h3>
                  {booking.startPeriodName}
                  {booking.endPeriodName &&
                    booking.endPeriodName != booking.startPeriodName && (
                      <> - {booking.endPeriodName}</>
                    )}
                </h3>}
              </Col>
            </Row>
            <Row>
              {data.campuses.length > 1 && (
                <FormColumn label={`Campus`}>
                  <Select
                    defaultValue={data.campuses[0]?.id}
                    options={campuses}
                    onChange={(element) =>
                      onUpdateValue("campusId", element.value)
                    }
                  ></Select>
                </FormColumn>
              )}

              <FormColumn label={`Room`}>
                <Select
                  options={rooms}
                  value={rooms.filter(option => option.value === booking.room)}
                  onChange={(element) => onUpdateValue("room", element.value)}
                ></Select>
              </FormColumn>

              <Col xs={12} md={6} className={'p-0'}>
                <FormColumn label={`Year Level`} className={'float-left'}>
                  <Select
                    options={yearLevels}
                    value={yearLevels.filter(option => option.value === booking.yearLevel)}
                    isDisabled={!booking.room}
                    onChange={(element) => {
                      onUpdateValue("yearLevel", element.value)
                      onUpdateValue("class", null);
                    }
                    }
                  ></Select>
                </FormColumn>
                <FormColumn label={`Class`} className={'float-left'}>
                  <Select
                    options={classes}
                    value={classes.filter(option => option.value === booking.class)}
                    isDisabled={!booking.yearLevel}
                    onChange={(element) =>
                      onUpdateValue("class", element.value)
                    }
                  ></Select>
                </FormColumn></Col>
            </Row>
            {booking.id && user.isAdmin && <>
              <Row>
              <FormColumn label={`Staff Name`} columns={1}>
              <Select
                  options={usersValue}
                  value={usersValue.filter(x=>x.value == (booking.bookingUserId || booking.userId))}
                  onChange={(element) => {
                    onUpdateValue("bookingUserId", element.value)
                    onUpdateValue("bookingUserName", element.label)
                  }}
                ></Select>
              </FormColumn>
</Row>
            </>}
              <hr className={`my-3`} />

            <Row>
              {booking.id ? <FormColumn label={`Practical Name`} columns={1}>
                <Input type="text" readOnly="true" name="practicalName" id="practicalName" value={booking.practicalName} />

              </FormColumn> :
                <FormColumn label={`Practical Name`} columns={1}>
                  <Creatable
                    options={practicals}
                    isDisabled={!booking.class}
                    onChange={(e) => {
                      setIsNewPractical(e.__isNew__);
                      //find practical
                      if (e.__isNew__) {
                        onUpdateValue('practicalName', e.label);
                        setSavedPractical(null);
                      }
                      else {
                        var saved = savedBookings.find(x => x.id === e.value);
                        onUpdateValue('practicalName', saved.name);
                        setSavedPractical(e.value);
                      }

                    }}
                  ></Creatable>
                </FormColumn>}

              {booking.practicalName && !booking.practicalName.isNew && (<>
                <FormColumn label={`Sets Required`} columns={1}>
                  <Input type="number" step="1" name="setsRequired" id="setsRequired" value={booking.setsRequired} onChange={e => { onUpdateValues({ setsRequired: e.target.value }); }} />
                </FormColumn>
                <FormColumn label={`Book Reference`} columns={1}>
                  <Input type="text" name="bookReference" id="bookReference" value={booking.bookReference} onChange={e => { onUpdateValues({ bookReference: e.target.value }); }} />
                </FormColumn>
              </>)}
              {booking.practicalName && isNewPractical && (
                <Col className={`d-flex align-content-center`} xs={12}>
                  <label className="custom-toggle">
                    <input type="checkbox" id="SavePractical" checked={booking.SavePractical} onChange={e => onUpdateValues({ savePractical: e.target.checked })} />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                  <label className={`ml-2`} htmlFor={`SavePractical`}>
                    {" "}
                    Save This Practical
                   </label>
                </Col>
              )}
            </Row>
            <hr className={`my-3`} />
            <Row>
              <Col className={`d-flex align-content-center`} xs={12}>
                <label className="custom-toggle">
                  <input type="checkbox" id="TechnicianAssistanceRequired" checked={booking.technicianAssistanceRequired} onChange={e => onUpdateValues({ technicianAssistanceRequired: e.target.checked })} />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
                <label className={`ml-2`} htmlFor={`TechnicianAssistanceRequired`}>
                  {" "}
                Technician Assistance Required
              </label>
              </Col>

            </Row>
            <hr className={`my-3`} />
            <Row>
              <FormColumn label={`Notes`} columns={1}>
                <Input type="textarea" name="BookingNotes" id="BookingNotes" rows={3} value={booking.bookingNotes} onChange={e => onUpdateValues({ bookingNotes: e.target.value })} />
              </FormColumn>
            </Row>
          </>
        )}</div>
    </div>
  );
};

