import React from 'react';


export default ({ location }) => {

    const section = () => {
        let pathName = location.pathname || ``;
        pathName = pathName.substr(1);
        pathName = pathName.substr(0, pathName.indexOf(`/`));
        pathName = pathName.toLowerCase();
        let icon = ``;
        let title = ``;
        let sectionName = ``;

        switch (pathName) {
            case `lab`:
                icon = `flask`;
                title = `Bookings`;
                sectionName = `Lab Bookings`;
                break;
            default:
                icon = `flask`;
                title = `Bookings`;
                sectionName = `Lab Bookings`;
                break;
        }

        return <div>
            <img
                className={`mr-3`}
                style={styles.icon}
                src={require(`../../assets/img/icons/${icon}.svg`)}
            ></img>
            <span className="d-block text-uppercase ls-1 mb-0 h6">{sectionName}</span>
            <h1 style={styles.h1}>{title}</h1>

        </div>;
    }

    return <>{section()}</>
};

const styles = {
    icon: { maxWidth: 40, float: `left`, display: `block`, float: `left` },
    h1: { lineHeight: 1 }
};
