import ApiService from "./ApiService";
import StorageService from "infrastructure/StorageService";
import moment from 'moment';

export default {
  periods: async () => {
    let periods = StorageService.get(StorageService.keys.periods);
    if (periods) return periods;

    //get periods

    await ApiService.fromStorage(`timetable.json`).then((json) => {
      StorageService.set(StorageService.keys.periods, json);
      periods = json;
    });
    return periods;
  },
  terms: async () => {
    let terms = StorageService.get(StorageService.keys.terms);
    if (terms) return terms;

    //get terms

    await ApiService.fromStorage(`lab-bookings/terms.json`)
      .then((json) => {
        StorageService.set(StorageService.keys.terms, json.terms);
        terms = json.terms;
      })
      .catch(() => {
        StorageService.set(StorageService.keys.terms, "");
      });
    return terms;
  },
  leadTime: async () => {
    let leadTime = StorageService.get(StorageService.keys.leadTime);
    if (leadTime) return leadTime;

    //get terms

    await ApiService.fromStorage(`lab-bookings/leadtime.json`)
      .then((json) => {
        StorageService.set(StorageService.keys.leadTime, json.leadTime);
        leadTime = json.leadTime;
      })
      .catch(() => {
        StorageService.set(StorageService.keys.leadTime, "3");
      });
    return leadTime;
  },
  labBookings: {
    campuses: async () => {
      let data = StorageService.get(StorageService.keys.labBookingCampuses);
      if (data) return data;

      //get periods

      await ApiService.fromStorage(`lab-bookings/campuses.json`).then(
        (json) => {
          StorageService.set(StorageService.keys.labBookingCampuses, json);
          data = json;
        }
      );
      return data;
    },
    riskTemplate: async () => {
      let data = StorageService.get(StorageService.keys.labBookingRiskTemplate);
      if (data) return data;

      //get periods

      await ApiService.fromStorage(`lab-bookings/risktemplate.json`).then(
        (json) => {
          StorageService.set(StorageService.keys.labBookingRiskTemplate, json);
          data = json;
        }
      );
      return data;
    },
  },

  addBusinessDays: (date, days) => {
    var dt = new Date(date);
    dt.setHours(0,0,0,0);
    days = days+1;
    var d = moment(dt).add(Math.floor(days / 5) * 7, "d");
    var remaining = days % 5;
    while (remaining) {
      d.add(1, "d");
      if (d.day() !== 0 && d.day() !== 6) remaining--;
    }
    return d;
  },
};
