
export default {
    keys: {
        periods : `periods`,
        user: `user`,
        sasToken: `sastoken`,
        labBookingCampuses: `labBookingCampuses`,
        labBookingRiskTemplate: `labBookingRiskTemplate`,
        terms: `terms`,
        leadTime: `leadTime`
    },
    get : (key, defaultValue) => {
        var storageObject = sessionStorage.getItem(key);
        if (storageObject){
            try{
                const jsonObject = JSON.parse(storageObject);
                return jsonObject;
            }catch(exp){
                return defaultValue;
            }
        }
        return defaultValue;
    },
    set: (key, value) => {
        var strObject  = JSON.stringify(value);        
        sessionStorage.setItem(key, strObject);
    },
    remove: (key)  => {
        sessionStorage.removeItem(key);
    },
    clear: () => sessionStorage.clear()
}
