import React from "react";
import { Row, Col } from "reactstrap";

export default ({ sectionName, title, icon, onCloseButtonPress }) => {
  return (
    <Row className={`border-bottom mb-3 d-flex justify-content-between `}>
      <Col className={`mb-2`}>
        <img
          alt={` `}
          className={`mr-3`}
          style={styles.icon}
          src={require(`../../assets/img/icons/${icon}.svg`)}
        ></img>

        <span className="d-block text-uppercase ls-1 mb-0 h6">{sectionName}</span>
        <h3>{title}</h3>
      </Col>
    </Row>
  );
};

const styles = {
  icon: { maxWidth: 40, float: `left`, display: `block` },
};
