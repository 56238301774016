
import React from 'react';
import './Loader.scss';
export default (({size, className, color}) => {
    if (!size){
        size = `lg`;
    }
    if (!color){
        color = 'primary';
    }
    return <div className={`loader loader-${size} ${className || ''} loader-${color}`}><div></div><div></div><div></div><div></div></div>
})