// import React from 'react';
 
// const UserContext = React.createContext(null);
 
// export default UserContext;



import React, { useState } from 'react';
import StorageService from 'infrastructure/StorageService';

const UserContext = React.createContext({
  givenName: '',
  fullName: '',
  token: '',
  sasToken: '',
  isAuthenticated: false,
  isAdmin: false,
  changeDetails: () => {},
  login: () => {},
  logout: () => {}
});

export default UserContext;

export const UserContextProvider = ({ children }) => {
  const updateValues = values => {
    setValue(prevState => {
      const newState = {
        ...prevState,
        ...values
      };
      if (values){
        StorageService.set(StorageService.keys.user, newState);
      }else{
        StorageService.remove(StorageService.keys.user);
      }
      return (newState)});
  };

  const changeDetails = async values => {
  };

  const login = async values => {
    const newValues = {...values};
    newValues.isAuthenticated = values.token != null && values.token.length > 0
    updateValues(newValues);
  };

  const logout = async () => {
     updateValues(null);
     StorageService.clear();
  };

  const [value, setValue] = useState({
    givenName: '',
    fullName: '',
    token: null,
    changeDetails: values => changeDetails(values),
    login: values => login(values),
    logout
  });

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
