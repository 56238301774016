import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, useParams } from "react-router-dom";
import FormColumn from "components/Common/FormColumn";
import moment from 'moment';
import DataService from "infrastructure/Services/DataService";
import ApiService from "infrastructure/Services/ApiService";
import { Card, CardHeader, CardBody, NavItem, NavLink, Nav, Container, Row, Col, Button, Input } from "reactstrap";
import Loader from 'components/Common/Loader';
import UserContext from "context/UserContext";
import { toast } from 'react-toastify';
import { CirclePicker } from 'react-color';
import RiskMatrix from 'components/Common/RiskMatrix';
export default ((props) => {
  const [booking, setBooking] = useState();
  const [technicianNotes, setTechnicianNotes] = useState();
  const { id } = useParams();
  const { date } = useParams();
  const user = useContext(UserContext);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const tempPeriods = await DataService.periods();
    const campuses = await DataService.labBookings.campuses();

    ApiService.get(`booking/lab/${date}/${id}`)
      .then(resp => {
        //get periods
        var startPeriod = tempPeriods.find(m => m.id === resp.startPeriod);
        var endPeriod = tempPeriods.find(m => m.id === resp.endPeriod);
        var campus = campuses.find(m => m.id === resp.campusId);
        resp.startPeriod = startPeriod.order;
        resp.campus = campus.name;
        var startTime = startPeriod.startTime.split(':');
        resp.startHour = startTime[0];
        resp.startMinute = startTime[1];
        resp.startPeriodName = startPeriod.name;

        var endtime = endPeriod.startTime.split(':');
        resp.endHour = endtime[0];
        resp.endMinute = endtime[1];
        resp.endPeriod = endPeriod.order;
        resp.endPeriodName = endPeriod.name;
        setTechnicianNotes(resp.technicianNotes);
        setBooking(resp);
      })
  }


  const saveTechnicianNotes = () => {
    ApiService.put(`booking/lab/${date}/${id}`, { note: technicianNotes })
      .then(resp => {
        if (resp && resp.success) {
          toast.success("Booking Saved");
        }
      })
  }

  const saveColour = (colour) => {
    ApiService.put(`booking/lab/${date}/${id}/colour`, { colour: colour.hex })
      .then(resp => {
        if (resp && resp.success) {
          toast.success("Booking Colour Updated");
        }
      })
  }

  const getAttachment = (attachmentId) => {
    ApiService.get(`booking/lab/${date}/${id}/attachment/${attachmentId}`)
      .then(resp => {
        if (resp && resp.url) {
          window.open(resp.url,'_newtab');
        }
      });
  }

  return <Container className="mt--7" fluid>
    <Row>
      <Col className="mb-5 mb-xl-0" xl="12">
        <Card className="shadow">
          <CardBody>
            {!booking && <Row><Col xs={12} className="text-center"><Loader /></Col></Row>}
            {booking && <>
              <Row className={`d-flex align-items-center`}>
                <Col xs={12} md={6}>
                  <h6 className="text-uppercase ls-1 mb-0">Date</h6>
                  <h3>{new moment(booking.bookingDate).format("dddd, LL")}</h3>
                </Col>
                <Col xs={12} md={6}>
                  <h6 className="text-uppercase ls-1 mb-0">Time</h6>
                  <h3>
                    {booking.startPeriodName}
                    {booking.endPeriodName &&
                      booking.endPeriodName != booking.startPeriodName && (
                        <> - {booking.endPeriodName}</>
                      )}
                  </h3>
                </Col>
              </Row>
              <Row>
                <FormColumn label={`User`}>
                  <h4>{booking.userName}</h4>
                </FormColumn>
                <FormColumn label={`Created On`}>
                  <h4>{new moment(booking.createdOn).format("dddd, LL hh:mm:ss A")}</h4>
                </FormColumn>
                <FormColumn label={`Campus`}>
                  <h4>{booking.campus}</h4>
                </FormColumn>

                <FormColumn label={`Room`}>
                  <h4>{booking.room}</h4>
                </FormColumn>

                <FormColumn label={`Year Level`}>
                  <h4>{booking.yearLevel}</h4>
                </FormColumn>
                <FormColumn label={`Class`}>
                  <h4>{booking.class}</h4>
                </FormColumn>
              </Row>
              <Row>
                <FormColumn label={`Practical Name`} columns={2}>
                  <h4>{booking.practicalName}</h4>
                </FormColumn>
                <FormColumn label={`Sets Required`} columns={2}>
                  <h4>{booking.setsRequired}</h4>
                </FormColumn>
                <FormColumn label={`Book Reference`} columns={2}>
                  <h4>{booking.bookReference}</h4>
                </FormColumn>
              </Row>
              <Row>
                <FormColumn label={`Technician Assistance Required`} columns={2}>
                  <h4>{booking.technicianAssistanceRequired == true ? 'Yes' : 'No'}</h4>
                </FormColumn>
              </Row>
              <Row>
                <FormColumn label={`Booking Notes`} columns={1}>
                  <h4>{booking.bookingNotes}</h4>
                </FormColumn>
              </Row>
              {user.isAdmin && <><Row>
                <FormColumn label={`Technician Notes`} columns={1}>
                  <Input type="textarea" name="TechnicianNotes" id="TechnicianNotes" rows={3} onChange={e => setTechnicianNotes(e.target.value)} value={technicianNotes} />
                  <Button
                    color="primary" size="sm"
                    type="button" disabled={technicianNotes == booking.technicianNotes} className={`mt-2 float-right`} onClick={saveTechnicianNotes}>Save</Button>
                </FormColumn>
              </Row>
                <hr className={`my-3`} />
                <Row>
                  <FormColumn label={`Colour`} columns={1}>
                    <div className={'mt-2'}><CirclePicker
                      onChangeComplete={saveColour}
                      width={'100%'} color={booking.color} colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#17a1eb", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]} /></div>
                  </FormColumn>
                </Row>
              </>}
              {booking.attachments && booking.attachments.length > 0 &&<>
                <hr className={`my-3`} />
                <Row className={`d-flex align-items-center`}>
                  <Col xs={12} md={6}>
                    <h3>Attachments</h3>
                    {booking.attachments.map(attachment => {
                      return <>
                        <p><a href={`#`} onClick={(e) => {e.preventDefault(); getAttachment(attachment.id);}}>{attachment.name}</a></p>
                      </>
                    })}
                  </Col>
                </Row></>
              }
              {booking.risks.map(risk => {
                return <>
                  <hr className={`my-3`} />
                  <Row className={`d-flex align-items-center`}>
                    <Col xs={12}>
                      <h3>{risk.name} Risks</h3>
                    </Col>
                  </Row>
                  {risk.hazards.map((hazard) => (
                    <>
                      <Row className="d-flex align-items-center mb-2">
                        <Col xs={6}>
                          {hazard.name}
                        </Col>
                        <Col xs={6}>
                          {hazard.value}
                        </Col>
                      </Row>
                    </>))}
                  <>
                    <Row className="d-flex align-items-center my-2">
                      <Col xs={6}>
                        <h4>Control Measures</h4>
                      </Col>
                    </Row>
                  </>
                  {risk.controlMeasures.map((method) => (<Row className="d-flex align-items-center mb-2">
                    <Col xs={6}>
                      {method.name}
                    </Col>
                    <Col xs={6}>
                      {method.value ? 'Yes' : 'No'}
                    </Col></Row>
                  ))}
                  <Row className="d-flex align-items-center mt-4">
                    <Col xs={6}>
                      <h4>Notes</h4>
                    </Col>
                    <Col xs={6}>
                      {risk.notes}
                    </Col>
                  </Row>
                </>
              })}
              <Row>
                <FormColumn label={`Notes`} columns={1}>
                  <h4>{booking.notes}</h4>
                </FormColumn>
              </Row>
              <hr className={`my-3`} />
            </>}
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>;

});