import React, { useEffect, useState, useMemo } from "react";
import FormColumn from "../../../../components/Common/FormColumn";
import FormStepHeader from "../../../../components/Common/FormStepHeader";
import ApiService from "infrastructure/Services/ApiService";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import {
  Table,
  Col,
  Input,
  Row,

} from "reactstrap";


export default ({ booking, onUpdateValues, currentStep, index }) => {
  const [quantities, setQuantities] = useState([]);
  const [data, setData] = useState(null);
useEffect(() => {

}, [booking])
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: files => updateFiles(files)
  });


  const onUpdateValue = (prop, value) => {
    const args = {};
    args[prop] = value;

    onUpdateValues(args)
  }

  const style = useMemo(
    () => ({
      ...styles.baseStyle,
      ...(isDragActive ? styles.activeStyle : {}),
      ...(isDragAccept ? styles.acceptStyle : {}),
      ...(isDragReject ? styles.rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );


  const files = acceptedFiles.map((file) => (
    <Row key={file.path} className={`d-flex align-items-center mb-1`}>
      <Col xs={11}>{file.path}</Col>
      <Col xs={1} className={`text-right`}>
      <a className="cursor-pointer" onClick={() => deleteUploadedFile(file.path)}><i className={`fa fa-times`}></i></a>
      </Col>
    </Row>
  ));

  useEffect(() => {

  }, []);

  useEffect(() => {

  }, [booking]);
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const deleteAttachment = (attachmentId) => {
    var date = new moment(booking.bookingDate).format("YYYY-MM-DD");
    ApiService.delete(`booking/lab/${date}/${booking.id}/attachment/${attachmentId}`)
      .then(resp => {
        var attachments = booking.attachments;
        var aIndex = attachments.findIndex(x => x.id == attachmentId);
        attachments.splice(aIndex, 1);
        onUpdateValue('attachments', attachments);
      });
  }

  const deleteUploadedFile = async (name) => {
    const _files = booking.attachments && booking.attachments.length > 0 ? [...booking.attachments] : [];
    var aIndex = _files.findIndex(a => (a.file && a.file.length > 0) && a.name === name);
     if (!aIndex || aIndex < 0) {
      return
    }
    _files.splice(aIndex, 1);
    onUpdateValue('attachments', _files);

  }

  const updateFiles = async (files) => {
    const _files = booking.attachments && booking.attachments.length > 0 ? [...booking.attachments] : [];
    for (const f of files){
      var content = await toBase64(f);
      _files.push({
        mimeType: f.type,
        name: f.name,
        file: content
      })
    }
    onUpdateValue('attachments', _files);
  }

  let className = `step`

  if (currentStep < index) {
    className += ` next`;
  } else if (currentStep > index) {

    className += ` prev`;
  } else {
    className += ` current`;
  }
  return (
    <div className={className}>
      <FormStepHeader
        sectionName={`Booking Details`}
        title={`Attachments`}
        icon={`flask`}
      />
          <Row>
        <FormColumn label={`Upload Attachments`} columns={1}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p className={`mb-0`}>
              Drag and drop some files here, or click to select files
                  </p>
          </div>
        </FormColumn>
      </Row>

      {booking.attachments &&
        <>
      
          <Row className={`d-flex align-items-center mb-1`}>
            <Col xs={11}>
              <span className={`h6 text-uppercase`}>File Name</span>
            </Col>

            <Col xs={1} className={`text-right`}></Col>
          </Row>

          {booking.attachments.map(a => <Row key={a.id} className={`d-flex align-items-center mb-3`}>
            <Col xs={11}>
              {a.name}</Col>
            <Col xs={1} className={`text-right`}>
              {a.id ? 
              <>{booking.id && <a className="cursor-pointer" onClick={() => deleteAttachment(a.id)}><i className={`fa fa-times`}></i></a>}</> : 
              <a className="cursor-pointer" onClick={() => deleteUploadedFile(a.name)}><i className={`fa fa-times`}></i></a>}
            </Col>
          </Row>)}
        </>
      }  
    </div>
  );
};


const styles = {

  baseStyle: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  activeStyle: {
    borderColor: "#2196f3",
  },
  acceptStyle: {
    borderColor: "#00e676",
  },

  rejectStyle: {
    borderColor: "#ff1744"
  }
}


