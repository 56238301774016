import React from 'react';
import { Col, Row, Table } from 'reactstrap';

export default (() => {
    return <>
        <table style={{ borderCollapse: `separate`, borderSpacing: 10, maxWidth: `100%`, textAlign: `center`, fontSize: `0.7rem` }}>
            <tr>
                <td rowspan="2" style={styles.cell}>
                    <b>Likelyhood</b><br />
                    (likelihood of harm caused given the circumstances)
                </td>
                <td colspan="5" style={styles.cell}>
                    <b>Consequences</b>
                </td>
            </tr>
            <tr>
                <td style={styles.cell}>
                    <b>Insignificant</b><br />
                    (no injuries)
                </td> <td style={styles.cell}>
                    <b>Minor</b><br />
                    1st aid/immediate containment
                </td> <td style={styles.cell}>
                    <b>Moderate</b><br />
                    Medical treatment/ release contained with outside help
                </td> <td  style={styles.cell}>
                    <b>Major</b><br />
                    Extensive injuries/ off site release with no detrimental effect
                </td> <td  style={styles.cell}>
                    <b>Catastrophic</b><br />
                    Death / toxic release off site with detrimental effect
                    </td>
            </tr>

            <tr>
                <td style={styles.cell}><b>A</b><br/>Almost certain</td>
                <td style={styles.high}>High</td>
                <td style={styles.high}>High</td>
                <td style={styles.extreme}>Extreme</td>
                <td style={styles.extreme}>Extreme</td>
                <td style={styles.extreme}>Extreme</td>
            </tr>
            <tr>
                <td style={styles.cell}><b>B</b><br/>likely</td>
                <td style={styles.moderate}>Moderate</td>
                <td style={styles.high}>High</td>
                <td style={styles.high}>High</td>
                <td style={styles.extreme}>Extreme</td>
                <td style={styles.extreme}>Extreme</td>
            </tr>
            <tr>
                <td style={styles.cell}><b>C</b><br/>possible</td>
                <td style={styles.low}>Low</td>
                <td style={styles.moderate}>Moderate</td>
                <td style={styles.high}>High</td>
                <td style={styles.extreme}>Extreme</td>
                <td style={styles.extreme}>Extreme</td>
            </tr>
            <tr>
                <td style={styles.cell}><b>D</b><br/>unlikely</td>
                <td style={styles.low}>Low</td>
                <td style={styles.low}>Low</td>
                <td style={styles.moderate}>Moderate</td>
                <td style={styles.high}>High</td>
                <td style={styles.extreme}>Extreme</td>
            </tr>
            <tr>
                <td style={styles.cell}><b>E</b><br/>rare</td>
                <td style={styles.low}>Low</td>
                <td style={styles.low}>Low</td>
                <td style={styles.moderate}>Moderate</td>
                <td style={styles.high}>High</td>
                <td style={styles.high}>High</td>
            </tr>

        </table>
    </>
});

const styles = {
    cell: {backgroundColor: `#ced4da`, padding: 16, width: `16.66%`, margin: 10, borderRadius: 6, textTransform: `capitalize`},
    high: {color: `#fff`, padding: 16, width: `16.66%`, backgroundColor: `#dc6835`, margin: 10, borderRadius: 6},
    extreme: {color: `#fff`, padding: 16, width: `16.66%`, backgroundColor: `#dc3545`, margin: 10, borderRadius: 6},
    low: {color: `#fff`, padding: 16, width: `16.66%`, backgroundColor: `#28a745`, margin: 10, borderRadius: 6},
    moderate: {color: `#fff`, padding: 16, width: `16.66%`, backgroundColor: `#ffc107`, margin: 10, borderRadius: 6},
}