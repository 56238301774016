/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import UserContext, { UserContextProvider } from "./context/UserContext";
import PrivateRoute from "./infrastructure/ProtectedRoute";
import { HotKeys } from "react-hotkeys";
import Layout from "layouts/Layout.js";
import AuthLayout from "layouts/Auth.js";
import StorageService from "infrastructure/StorageService";
export const App = () => {
  return (
    <UserContextProvider>
      <AppRoutes />
    </UserContextProvider>
  );
};

const keyMap = {
  increaseZoom: "=",
  decreaseZoom: "-"
};


const AppRoutes = () => {
  const userContext = useContext(UserContext);
  const [userContextHasLoaded, setUserContextHasLoaded] = useState(false);

  useEffect(() => {
    const storedCredentials = StorageService.get(StorageService.keys.user);
    if (storedCredentials && storedCredentials.token) {
      userContext.login(storedCredentials);
    }
    setUserContextHasLoaded(true);
  }, []);
  return  <HotKeys keyMap={keyMap}>{userContextHasLoaded ? (
    <BrowserRouter>
      <Switch>
        {/* <PrivateRoute
          authed={userContext.isAuthenticated}
          path="/dashboard"
          component={Layout}
        /> */}
        <PrivateRoute
          authed={userContext.isAuthenticated}
          path="/lab/bookings"
          component={Layout}
        />
        <PrivateRoute
          authed={userContext.isAuthenticated}
          path="/lab/booking/:date/:id"
          component={Layout}
        />

      <PrivateRoute
          authed={userContext.isAuthenticated}
          path="/bookings/create"
          component={Layout}
        />
          <PrivateRoute
          authed={userContext.isAuthenticated}
          path="/admin/settings"
          component={Layout}
        />
        <PrivateRoute
        authed={userContext.isAuthenticated}
        path="/admin/saved-bookings"
        component={Layout}
      />
      <PrivateRoute
      authed={userContext.isAuthenticated}
      path="/admin/terms"
      component={Layout}
    />
        <Route
          path="/auth/login"
          render={(props) => <AuthLayout {...props} />}
        />
        <Redirect from="/" to="/lab/bookings" />
      </Switch>
    </BrowserRouter>
  ) : (
    <h1>Loading...</h1>
  )}</HotKeys>
};
