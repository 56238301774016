import React from "react";
import { Row, Col, Input, Label } from "reactstrap";
import FormStepHeader from "../../../../components/Common/FormStepHeader";
import FormColumn from "../../../../components/Common/FormColumn";
import RiskMatrix from "components/Common/RiskMatrix";
export default ({ booking, riskTemplate, onUpdateValue, currentStep, index }) => {


  const setListValue = (list, name, value) => {

    if (!booking.risks) {
      const newValue = { id: riskTemplate.id, name: riskTemplate.name };
      newValue[list] = [{ name, value }]
      onUpdateValue(riskTemplate, newValue);
      return;
    }

    const currentRiskIndex = booking.risks.findIndex(x => x.id === riskTemplate.id);
    if (currentRiskIndex > -1) {
      const currentRisk = booking.risks[currentRiskIndex];

      if (currentRisk[list] == null) {
        currentRisk[list] = [{ name, value }]
        onUpdateValue(riskTemplate, currentRisk);
      }

      const currentHazardIndex = currentRisk[list].findIndex(x => x.name === name);
      if (currentHazardIndex > -1) {
        currentRisk[list][currentHazardIndex].value = value;
      } else {
        currentRisk[list].push({ name, value });
      }

      onUpdateValue(riskTemplate, currentRisk);
    } else {
      const newValue = { id: riskTemplate.id, name: riskTemplate.name };
      newValue[list] = [{ name, value }]
      onUpdateValue(riskTemplate, newValue);
    }
  }
  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }
  const setNoteValue = (value) => {

    if (!booking.risks) {
      const newValue = { id: riskTemplate.id, name: riskTemplate.name, notes: value };

      onUpdateValue(riskTemplate, newValue);
      return;
    }

    const currentRiskIndex = booking.risks.findIndex(x => x.id === riskTemplate.id);
    if (currentRiskIndex > -1) {
      const currentRisk = booking.risks[currentRiskIndex];
      currentRisk.notes = value;

      onUpdateValue(riskTemplate, currentRisk);
    } else {
      const newValue = { id: riskTemplate.id, name: riskTemplate.name, notes: value };
      onUpdateValue(riskTemplate, newValue);
    }
  }
  const getListValue = (list, name) => {
    if (!booking.risks) return null;

    const currentRiskIndex = booking.risks.findIndex(x => x.id === riskTemplate.id);

    if (currentRiskIndex < 0) return null;

    const currentRisk = booking.risks[currentRiskIndex];

    if (currentRisk[list] == null) return null;


    var currentHazardIndex = currentRisk[list].findIndex(x => x.name === name);
    if (currentHazardIndex < 0) return null;

    return currentRisk[list][currentHazardIndex].value;
  }

  const renderHazards = riskTemplate.hazards.map((hazard) => (
    <Row className="d-flex align-items-center mb-2" key={hazard.id}>
      <Col xs={12} md={8}>
        {hazard.name}
      </Col>

      <Col xs={12} md={4}>
        <Input type="select" name="select" id="exampleSelect" bsSize={`sm`} value={getListValue('hazards', hazard.name) || ''} onChange={(e) => setListValue('hazards', hazard.name, e.target.value)}>
          <option>None</option>
          <option>Low</option>
          <option>Medium</option>
          <option>High</option>
          <option>Extreme</option>
        </Input>
      </Col>
    </Row>
  ));

  const renderControlMethods = riskTemplate.controlMeasures.map((method) => (
    <Col xs={12} md={4} key={method.name}>
      <div className="custom-control custom-checkbox mb-3">
        <input
          className="custom-control-input"
          id={`cbx_${method.id}`}
          type="checkbox"
          checked={getListValue('controlMeasures', method.name) === true}
          onChange={e => setListValue('controlMeasures', method.name, e.target.checked)}
        />
        <label className="custom-control-label" htmlFor={`cbx_${method.id}`}>
          {method.name}
        </label>
      </div>
    </Col>
  ));



  return (<>
    <div className="mb-5">
      <h3>{`${toTitleCase(riskTemplate.name)} Risks`}</h3>
      <Row>
        <Col>
          <label className="text-uppercase ls-1 mb-0 h6">Hazards Identified</label>
        </Col>
      </Row>
      {renderHazards}
      <Row>
        <Col>
          <hr className={`my-2 p-0`} />
        </Col>
      </Row>
      <Row>
        <Col className={`my-1`}>
          <label className="text-uppercase ls-1 mb-0 h6">
            Control Measures Required
          </label>
        </Col>
      </Row>
      <Row>{renderControlMethods}</Row>
      <Row>
        <Col>
          <hr className={`my-2 p-0`} />
        </Col>
      </Row>  
    </div></>
  );
};
