import DataService from "./DataService";
import StorageService from "../StorageService";

const { default: Constants } = require("../Constants");

const ApiService = {
  getUrl: (path) => {
    var url = window.location.host.toLocaleLowerCase().startsWith("localhost") ? Constants.api.urls.development : Constants.api.urls.production;

    return `${url}${path}`
  },
  getHeaders: () => {
    const headers = {
      "Content-Type": "application/json"
    };

    const user = StorageService.get(StorageService.keys.user)
    if (user && user.token) {
      headers[`Authorization`] = `Bearer ${user.token}`;
    }

    return headers;
  },
  post: async (url, data, onError) => {
    const apiUrl = ApiService.getUrl(url);
    var request = new Request(apiUrl, {
      method: "POST",
      body: JSON.stringify(data),
      headers: ApiService.getHeaders(),
    });

    const resp = await fetch(request);
    if (resp.status === 200) {
      return await resp.json();
    }
    if (onError) onError();
  },
  put: async (url, data, onError) => {
    const apiUrl = ApiService.getUrl(url);
    var request = new Request(apiUrl, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: ApiService.getHeaders(),
    });

    const resp = await fetch(request);
    if (resp.status === 200) {
      return await resp.json();
    }
    if (onError) onError();
  },
  delete: async (url, data, onError) => {
    const apiUrl = ApiService.getUrl(url);
    var request = new Request(apiUrl, {
      method: "DELETE",
      body: JSON.stringify(data),
      headers: ApiService.getHeaders(),
    });

    const resp = await fetch(request);
    if (resp.status === 200) {
      return await resp.json();
    }
    if (onError) onError();
  },
  get: async (url, data, onError) => {
    let qs = "";

    for (var prop in data) {
      qs += `${prop}=${data[prop]}&`;
    }

    const apiUrl = `${ApiService.getUrl(url)}?${qs}`;
    var request = new Request(apiUrl, {
      method: "GET",
      headers: ApiService.getHeaders(),
    });

    const resp = await fetch(request);
    return await resp.json();
  },

  fromStorage: async (url, onError) => {
    var user = StorageService.get(StorageService.keys.user);

    if (user) {
      const apiUrl = `${Constants.api.storageUrl}${user.organisationId}/${url}?${user.sasToken}`;
      var request = new Request(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const resp = await fetch(request);
      if (resp.status === 401 || resp.status === 403) {
        //get new token
        const tokenJson = await ApiService.get(`auth/refresh-storage-token`);

        if (tokenJson && tokenJson.sasToken) {
          user.sasToken = tokenJson.sasToken;
          StorageService.set(StorageService.keys.user, user);
          return await ApiService.fromStorage(url, onError);
        }
      }
      return await resp.json();
    } else {
      onError && onError();
    }
  },
};

export default ApiService;
