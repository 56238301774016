/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import Timetable from "../components/Timetable/Timetable";
import TimetableList from "../components/Timetable/TimetableList";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";


export default () => {

  const [view, setView] = useState("timetable");
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [dates, setDates] = useState(null);

  useEffect(() => {
    setDates({
      startDate: new moment().startOf("week"),
      endDate: new moment().endOf("week"),
    });
  }, []);

  const handleDateChange = (d) => {
    const start = new moment(d).startOf("week");
    const end = new moment(d).endOf("week");
    const newDates = {
      startDate: start,
      endDate: end,
    };
    setDates(newDates);
    setOpenDatePicker(false);
  };

  return (
    <>
 
      {/* Page content */}
      {dates && (<Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase ls-1 mb-1">Bookings</h6>
                      <>
                      <span className="btn btn-secondary" id="PopoverLegacy">
                        <h2
                          className="mb-0 cursor-pointer d-inline float-left"
                          onClick={() => setOpenDatePicker(true)}
                        >
                          {dates.startDate.format("LL")} -{" "}
                          {dates.endDate.format("LL")}
                          <span className="text-muted ml-3">
                            <i className="ni ni-bold-down"></i>
                          </span>
                        </h2></span>
                        <UncontrolledPopover
                          trigger="legacy"
                          placement="bottom"
                          target="PopoverLegacy"
                          isOpen={openDatePicker}
                          toggle={() =>
                            setOpenDatePicker((prevstate) => !prevstate)
                          }
                        >
                          <PopoverBody>
                            <Datetime
                              renderInput={null}
                              timeFormat={false}
                              input={false}
                              onChange={handleDateChange}
                            />
                          </PopoverBody>
                        </UncontrolledPopover>
                      </>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3 cursor-pointer", {
                            active: view === "timetable",
                          })}
                          onClick={(e) => setView("timetable")}
                        >
                          <span className="d-none d-md-block">Timetable</span>
                          <span className="d-md-none">T</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3 cursor-pointer", {
                            active: view === "list",
                          })}
                          onClick={(e) => setView("list")}
                        >
                          <span className="d-none d-md-block">List</span>
                          <span className="d-md-none">L</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {view === "timetable" && (
                  <Timetable periods={[]} startDate={dates.startDate}></Timetable>
                )}
                {view === "list" && (
                  <TimetableList periods={[]}></TimetableList>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>  )}
    </>
  );
};
