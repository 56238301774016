import React from "react";
import { Col } from "reactstrap";

export default ({ label, children, columns, className }) => {
  columns = columns || 2;
  return (
    <Col xs={12} md={12/columns} className={`mb-2 ${className}`}>
      <label className="text-uppercase ls-1 mb-0 h6">{label}</label>
      {children}
    </Col>
  );
};
