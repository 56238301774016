
import UserContext from "context/UserContext";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import ApiService from "../../infrastructure/Services/ApiService";
import StorageService from '../../infrastructure/StorageService';
import DataService from '../../infrastructure/Services/DataService';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    NavItem,
    Input,
    Nav,
    Container,
    Row,
    Col,
    Table
} from "reactstrap";
import { toast } from 'react-toastify';

export default (() => {
    const [data, setData] = useState();

    const user = useContext(UserContext);
    const history = useHistory();
    const getData = async () => {
        const tempTerms = await DataService.terms();
        console.log(tempTerms);
             setData(tempTerms);
    }
    useEffect(() => {
        if (!user.isAdmin) {
            history.location = "/dashboard";
            return;
        }
        getData();
    }, []);

    const save = async () => {
        ApiService.put('terms', { terms: data }).then(async json => {
            toast.success("Terms Saved");

            await ApiService.fromStorage(`lab-bookings/terms.json`).then((json) => {      
                StorageService.set(StorageService.keys.terms, json.terms);
              })

        });
    }



    return <>
        <Container className="mt--7" fluid>
            <Row>
                <Col className="mb-5 mb-xl-0" xl="12">
                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                                <Col className=" d-flex align-items-center justify-content-between">
                                    <h3 className="mb-0">Administration &gt; Booking Terms</h3>
                                    <button className="btn btn-primary" onClick={save}>Save</button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                        {data && <CKEditor
                    editor={ ClassicEditor }
                    data={data}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const newData = editor.getData();
                        setData(newData);
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

    </>;
})