
import UserContext from "context/UserContext";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import ApiService from "../../infrastructure/Services/ApiService";
import StorageService from '../../infrastructure/StorageService';
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    NavItem,
    Input,
    Nav,
    Container,
    Row,
    Col,
    Table
} from "reactstrap";
import { toast } from 'react-toastify';

export default (() => {
    const [data, setData] = useState(null);

    const user = useContext(UserContext);
    const history = useHistory();
    const getData = async () => {
        ApiService.get(`savedbookings/lab/`)
            .then(resp => {
                let items = resp.items;
                resp.items.sort((a,b) => {
                    var an = a.yearLevel.replace( /^\D+/g, '');
                    var bn = b.yearLevel.replace( /^\D+/g, '');
                    return an - bn;
                });
                setData(items);
            })
    }
    useEffect(() => {
        if (!user.isAdmin) {
            history.location = "/dashboard";
            return;
        }
        getData();
    }, []);

    const deleteLesson = (id) => {
        const confirmDelete = window.confirm("Are you sure you would like to delete this booking?");
        if (!confirmDelete) return;
        
        
    ApiService.delete(`savedbooking/lab/${id}`).then(resp => {
        toast.success("Saved Booking Deleted");
        if (resp && resp.items){
            
            setData(resp.items);
        }
      })
    }

    return <>
        <Container className="mt--7" fluid>
            <Row>
                <Col className="mb-5 mb-xl-0" xl="12">
                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                                <Col className=" d-flex align-items-center justify-content-between">
                                    <h3>Administration &gt; Saved Bookings</h3>

                                </Col>
                            </Row></CardHeader><CardBody>


                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Year Level</th>
                                        <th scope="col">PracticalName</th>
                                        <th scope="col">User</th>
                                        <th scope="col">Year</th>
                                        <th scope="col" />
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.map(savedBooking =>
                                        <tr>
                                            <td>{savedBooking.yearLevel}</td>
                                            <td>{savedBooking.name}</td>
                                            <td>{savedBooking.userName}</td>
                                            <td>{savedBooking.year}</td>
                                            <td>
                                            <i
                                                        className={`fa fa-times cursor-pointer float-right`}
                                                        onClick={() => deleteLesson(savedBooking.id)}
                                                    ></i>
                                            </td>
                                        </tr>
                                    )}


                                </tbody>
                            </Table>

                            {/* {data && <>
                                {data.map((campus, ci) => <><Row>{
                                    <Col>
                                        <h4>{campus.name}</h4>
                                    </Col>

                                }</Row>

                                    <Row>
                                        <Col>
                                            <h5>Year Levels</h5>
                                            {campus.yearLevels.map((year, i) =>
                                                <div key={`y_${i}`} className="rounded d-flex align-items-center justify-content-between p-3 bg-light w-100 mb-3 float-left">
                                                    <span className="float-left">{year}</span>
                                                    <i
                                                        className={`fa fa-times cursor-pointer`}
                                                        onClick={(e) => {
                                                            setData((prevState) => {
                                                                const newState = [...prevState];
                                                                newState[ci].yearLevels.splice(i, 1);
                                                                return newState;

                                                            })
                                                        }}
                                                    ></i>
                                                </div>
                                            )}


                                            <div className="rounded py-3 w-100 mb-3">

                                                <Input type="text" value={newYear} className="form-control w-100 mb-1" onChange={e => {
                                                    const v = e.target.value;
                                                    setNewYear(v)
                                                }} />

                                                <button className="btn btn-primary w-100" disabled={!(newYear && newYear.length > 0)} onClick={() => {
                                                    setData((prevState) => {
                                                        const newState = [...prevState];
                                                        newState[ci].yearLevels.push(newYear);
                                                        return newState;
                                                    });
                                                    setNewYear('');
                                                }}>Add Year Level</button>
                                            </div>
                                        </Col>


                                        <Col>
                                            <h5>Classes</h5>
                                            {campus.classes.map((cls, i) =>
                                                <div key={`c_${i}`} className="rounded d-flex align-items-center justify-content-between p-3 bg-light w-100 mb-3 float-left">
                                                    <span className="float-left">{cls.name} - {cls.yearLevel}</span>
                                                    <i
                                                        className={`fa fa-times cursor-pointer`}
                                                        onClick={(e) => {
                                                            setData((prevState) => {
                                                                const newState = [...prevState];
                                                                newState[ci].classes.splice(i, 1);
                                                                return newState;

                                                            })
                                                        }}
                                                    ></i>
                                                </div>
                                            )}


                                            <div className="rounded py-3 w-100 mb-3">

                                                <Input type="text" value={newClass.name} className="form-control w-100 mb-1" onChange={e => {
                                                    const v = e.target.value;
                                                    setNewClass(prevState => {
                                                        const newState = { ...prevState };
                                                        newState.name = v
                                                        return newState;
                                                    })
                                                }} />
                                                <select className="form-control w-100 mb-1" onChange={e => {
                                                    const v = e.target.value;
                                                    setNewClass(prevState => {
                                                        const newState = { ...prevState };
                                                        newState.yearLevel = v
                                                        return newState;
                                                    })
                                                }}>
                                                    <option>Select...</option>
                                                    {campus.yearLevels.map((yl) => <option>{yl}</option>)}
                                                </select>
                                                <button className="btn btn-primary w-100" disabled={!(newClass.name && newClass.name.length > 0 && newClass.yearLevel && newClass.yearLevel.length > 0)} onClick={() => {
                                                    setData((prevState) => {
                                                        const newState = [...prevState];
                                                        newState[ci].classes.push(newClass);
                                                        return newState;
                                                    });
                                                    setNewClass({ name: '', yearLevel: '' });
                                                }}>Add Class</button>
                                            </div>

                                        </Col>
                                        <Col>
                                            <h5>Rooms</h5>
                                            {campus.rooms.map((room, i) =>
                                                <div key={`r_${i}`} className="rounded d-flex align-items-center justify-content-between p-3 bg-light w-100 mb-3 float-left">
                                                    <span className="float-left">{room}</span>
                                                    <i
                                                        className={`fa fa-times cursor-pointer`}
                                                        onClick={(e) => {
                                                            setData((prevState) => {
                                                                const newState = [...prevState];
                                                                newState[ci].rooms.splice(i, 1);
                                                                return newState;
                                                            })
                                                        }}
                                                    ></i>
                                                </div>
                                            )}


                                            <div className="rounded py-3 w-100 mb-3">

                                                <Input type="text" value={newRoom} className="form-control w-100 mb-1" onChange={e => {
                                                    const v = e.target.value;
                                                    setNewRoom(v)
                                                }} />

                                                <button className="btn btn-primary w-100" disabled={!(newRoom && newRoom.length > 0)} onClick={() => {
                                                    setData((prevState) => {
                                                        const newState = [...prevState];
                                                        newState[ci].rooms.push(newRoom);
                                                        return newState;
                                                    });
                                                    setNewRoom('');
                                                }}>Add Room</button>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                                )}
                            </>} */}

                        </CardBody>

                    </Card></Col></Row></Container>

    </>;
})