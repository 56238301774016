import React, { useState, useEffect, useRef, useContext } from "react";
import queryString from 'query-string'
import moment from "moment";
import { UncontrolledTooltip } from "reactstrap";
import _ from "lodash";
import "./Timetable.scss";
import DataService from "infrastructure/Services/DataService";
import { useHistory } from "react-router-dom";
import ApiService from "infrastructure/Services/ApiService";
import UserContext from "context/UserContext";
import AlertModal from "components/Common/AlertModal";
import StorageService from 'infrastructure/StorageService';
const cellPadding = 10;
const bookingHeight = 20;
let isMouseDown = false;
export default (props) => {
  const user = useContext(UserContext);
  const [periods, setPeriods] = useState([]);
  const [startHour, setStartHour] = useState(8);
  const [endHour, setEndHour] = useState(17);
  const [bookings, setBookings] = useState([]);
  const [days, setDays] = useState([]);
  const [now, setNow] = useState(new Date());
  const [newBooking, setNewBooking] = useState(null);
  const newBookingRef = React.useRef(newBooking);
  const [completeBooking, setCompleteBooking] = useState(null);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [cutOff, setCutoff] = useState(new moment().add(3, "days").set({hour:0,minute:0,second:0,millisecond:0}));

  const setIsMouseDown = (v) => { isMouseDown = v; }
  const history = useHistory();
  const table = useRef(null);
  const containerRef = useRef(null);
  const [terms, setTerms] = useState(null);

  
  useEffect(() => {
    if (completeBooking) {
      const search = queryString.stringify(completeBooking);
      history.push({ pathname: `/bookings/create`, search });
    }
  }, [completeBooking]);

  useEffect(() => {
    loadData();
    const timer = setInterval(() => {
      setNow(new Date());
    }, 10000);

    calculateCutOff();

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const _bookings = props.bookings || [];
    _bookings.forEach((booking) => {
      const _bookingDate = new Date(booking.date);
      booking.date = _bookingDate;
      booking.dayNumber = _bookingDate.getDay();
      booking.periodNumbers = [];
      if (!booking.colour) {
        booking.colour = "#17a1eb";
      }
      for (var i = booking.startPeriod; i <= booking.endPeriod; i++) {
        booking.periodNumbers.push(i);
      }
    });
    setBookings(_bookings);
  }, [props.bookings]);

  const calculateCutOff = async (date) => {
    if (!user.isAdmin){
      const leadTime = await DataService.leadTime();
      var dt = (date || new Date());
      dt.setHours(0,0,0,0);
      var newCutOff = DataService.addBusinessDays(dt, leadTime);
   
      setCutoff(newCutOff.set({hour:0,minute:0,second:0,millisecond:0}));
    }else
      setCutoff(new moment().add(-1, "years").set({hour:0,minute:0,second:0,millisecond:0}));
  };

  const loadData = async () => {
    const tempPeriods = await DataService.periods();
    const tempTerms = await DataService.terms();
    if (!user.isAdmin){
      await calculateCutOff();
    }
    setTerms(tempTerms);

    tempPeriods.forEach((period) => {
      period.startHour = period.startTime.substring(0, 2);
      period.startMinute = period.startTime.substring(3, 5);
      period.endHour = period.endTime.substring(0, 2);
      period.endMinute = period.endTime.substring(3, 5);
    });

    setPeriods(tempPeriods || []);

    const firstPeriod = tempPeriods.sort(
      (a, b) => parseFloat(a.startHour) - parseFloat(b.startHour)
    )[0];

    const _startHour = firstPeriod ? firstPeriod.startHour : 0;

    const lastPeriod = tempPeriods.sort(
      (a, b) => parseFloat(b.endHour) - parseFloat(a.endHour)
    )[0];
    const _endHour = lastPeriod ? parseInt(lastPeriod.endHour) + 1 : 1;
    const _days = [1, 2, 3, 4, 5, 6, 7].filter(
      (x) => tempPeriods.findIndex((p) => p.dayNumber == x) > -1
    );
    setStartHour(_startHour);
    setEndHour(_endHour);
    setDays(_days);
  };

  const getDayName = (dayIndex) => {
    const dayNames = ["Mon", "Tues", "Weds", "Thurs", "Fri", "Sat", "Sun"];
    return dayNames[dayIndex - 1];
  };

  const getHeight = (period) => {
    const minutes =
      (period.endHour - period.startHour) * 60 +
      (period.endMinute - period.startMinute);
    return minutes * 6;
  };

  const getWidth = (period) => {
    const minutes = getHeight(period);
    const divider = period.isBreak === true ? 2 : 1;

    const width = (minutes / divider);

    return props.zoomLevel > 0 ? width / props.zoomLevel : width * (0 - props.zoomLevel);
  };

  const editBooking = async (e, booking) => {
    e.preventDefault();
    e.stopPropagation();
    //get booking
    const tempPeriods = await DataService.periods();
    const campuses = await DataService.labBookings.campuses();
    const bookingDate = new moment(booking.date).format("YYYY-MM-DD");
    ApiService.get(`booking/lab/${bookingDate}/${booking.id}`)
      .then(resp => {
        //get periods
        var startPeriod = tempPeriods.find(m => m.id === resp.startPeriod);
        var endPeriod = tempPeriods.find(m => m.id === resp.endPeriod);
        var campus = campuses.find(m => m.id === resp.campusId);
        resp.startPeriod = startPeriod.order;
        resp.campus = campus.name;
        var startTime = startPeriod.startTime.split(':');
        resp.startHour = startTime[0];
        resp.startMinute = startTime[1];
        resp.startPeriodName = startPeriod.name;

        var endtime = endPeriod.startTime.split(':');
        resp.endHour = endtime[0];
        resp.endMinute = endtime[1];
        resp.endPeriod = endPeriod.order;
        resp.endPeriodName = endPeriod.name;
        resp.type = 'lab';
        props.onNewBooking(resp);
      })

  }

  const createNewBooking = (day, period, isClick) => {
    // let localCutOff = cutOff;
    // localCutOff.set({hour:0,minute:0,second:0,millisecond:0});

    const bookingDate = new moment(props.startDate)
                              .add(day, "days")
                              .set({hour:0,minute:0,second:0,millisecond:0});

                              console.log(bookingDate.format('yyyy-MM-DD hh:mm:ss'))
                              console.log(cutOff.format('yyyy-MM-DD hh:mm:ss'))

              
    const diff = bookingDate.diff(cutOff, 'days');

console.log(diff);
    if (diff < 0) return;

    document.addEventListener("mouseup", handleMouseUp);

    setNewBooking(() => {
      setIsMouseDown(true);
      var newState = {
        dayNumber: day,
        startPeriod: period,
        endPeriod: period,
        originalStart: period,
        periodNumbers: [period],
        colour: "#c00",
      };
      newBookingRef.current = newState;
      return newState;
    });
  };

  const mouseOverBooking = (e) => {
    e.stopPropagation();
  };

  const mouseOverPeriod = _.debounce((e, day, period) => {
    if (
      !isMouseDown ||
      !newBooking ||
      !day ||
      !period ||
      !e ||
      !e.target ||
      !e.target.hasAttribute("data-period")
    )
      return;


    if (newBooking.dayNumber === day) {
      setNewBooking((prevState) => {
        var newState = { ...prevState };

        if (period >= prevState.startPeriod) {
          newState.endPeriod = period;
        } else {
          newState.startPeriod = period;
        }

        newState.periodNumbers = [];
        for (var i = newState.startPeriod; i <= newState.endPeriod; i++) {
          newState.periodNumbers.push(i);
        }
        newBookingRef.current = newState;
        return newState;
      });
    }
  }, 10);

  const handleMouseUp = () => {


    document.removeEventListener("mouseup", handleMouseUp);
    setIsMouseDown(false);

    if (terms !== '') {
      setShowAlertModal(true);
    } else {
      handleBookingConfirm();
    }

  };

  const handleBookingCancel = () => {
    setShowAlertModal(false);
    setNewBooking(null);
  }

  const handleBookingConfirm = () => {
    setShowAlertModal(false);
    const bookingObject = {};
    const current = { ...newBookingRef.current };
    const bookingDate = new moment(props.startDate)
      .add(current.dayNumber, "days")
      .format("YYYY-MM-DD");
    bookingObject.bookingDate = bookingDate;
    //get startPeriod
    const startPeriodObject = periods.find(x => x.dayNumber === current.dayNumber && x.order === current.startPeriod);
    const endPeriodObject = periods.find(x => x.dayNumber === current.dayNumber && x.order === current.endPeriod);
    bookingObject.startPeriod = startPeriodObject.id;
    bookingObject.endPeriod = endPeriodObject.id;
    bookingObject.startPeriodName = startPeriodObject.name;
    bookingObject.endPeriodName = endPeriodObject.name;
    bookingObject.type = props.type || "lab";
    if (props.onNewBooking) {
      props.onNewBooking(bookingObject);
      setNewBooking(null);
    }
  };

  const compare = (arr1, arr2) => {
    return arr1.reduce((a, c) => a + arr2.includes(c), 0);
  };

  const renderBooking = (booking) => {
    //get width:
    let width = 0;

    for (var i = booking.startPeriod; i <= booking.endPeriod; i++) {
      var bookingPeriod = periods.find(
        (period) => period.dayNumber === booking.dayNumber && period.order === i
      );
      if (bookingPeriod) {
        width += getWidth(bookingPeriod);
      }
    }

    width =
      width -
      cellPadding * 2 +
      cellPadding * Math.max(0, booking.periodNumbers.length - 1);

    //const foundBookings = (booking.id ? bookings.filter(b => b.dayNumber == booking.dayNumber && booking.startPeriod != b.startPeriod && (b.periodNumbers.indexOf(booking.startPeriod) > -1)).length : 0);
    const foundBookings = (booking.id ? bookings.filter(b => b.dayNumber == booking.dayNumber && booking.startPeriod != b.startPeriod && booking.startPeriod < b.startPeriod && compare(booking.periodNumbers, b.periodNumbers) > 0).length : 0);

    let offsetHeight = (Math.max(foundBookings, 0) * 220) + 0;
    if (booking.id && foundBookings > 0)
      offsetHeight += 30;

      let d = booking.date;
      if (d)
        d.setHours(0,0,0, 0);
      const bookingDateMoment = new moment(d);

    return (
      <div
        onMouseEnter={mouseOverBooking}
        onMouseOver={mouseOverBooking}
        key={booking.id}
        style={{

          ...styles.booking,
          width: width + "px",
          backgroundColor: booking.colour,
          marginTop: offsetHeight,
        }}
        onClick={(e) => {
          e.preventDefault();
          var dm = bookingDateMoment.format('yyyy-MM-DD');
          history.push(`/lab/booking/${dm}/${booking.id}`);
        }}
      >
        {booking.id && <>
          {(user.isAdmin || (booking.canEdit && bookingDateMoment >= cutOff)) &&
            <a className={'float-left pr-2 pb-2'} onClick={(e) => editBooking(e, booking)}><i className={'fa fa-edit'}></i></a>}
            {booking.id && <>
          <span><b>{booking.practicalName}</b></span><br />
          <span>{booking.name}</span> &bull; <span>{booking.room}</span> &bull; <span>{booking.yearLevel}</span> &bull; <span>{booking.class}</span><br />
          {user.isAdmin && <span style={{color: '#000'}} title={booking.technicianNotes}>{booking.technicianNotes || '\u00A0'}</span>}</>}</>}
      </div>
    );
  };

  const renderRow = (day) => {
    return renderRowPeriods(day);
  };

  const renderRowHeader = (day) => {
    return (
      <div style={styles.periodHeader} key={`key_${day}`}>
        <div style={styles.periodHeader} id={`tooltip_${day}`}>
          <div>{getDayName(day)}</div>
        </div>
        <UncontrolledTooltip
          delay={0}
          placement="left"
          target={`tooltip_${day}`}
        >
          {new moment(props.startDate).add(day, "days").format("dddd, LL")}
        </UncontrolledTooltip>
      </div>
    );
  };

  const renderRowPeriods = (day) => {
    const periodDivs = [];
    periodDivs.push(renderRowHeader(day));
    const dayBookings = bookings.filter((booking) => booking.dayNumber === day);
    periods
      .filter((period) => period.dayNumber === day)
      .sort((a, b) => a.order - b.order)
      .forEach((period) => {
        const currentBookings = dayBookings.filter(
          (booking) => booking.startPeriodId === period.id
        );
        let offsetHeight = 0;
        let bookingOverlapsExistingBookings = false;

        if (newBooking) {
          for (var i = 0; i <= newBooking.periodNumbers.length - 1; i++) {
            const p = newBooking.periodNumbers[i];
            if (
              dayBookings.filter((b) => b.periodNumbers.includes(p)).length > 0
            ) {
              bookingOverlapsExistingBookings = true;
              break;
            }
          }
        }

        const overlapsPeriods =
          newBooking &&
          newBooking.dayNumber === day &&
          bookingOverlapsExistingBookings;

        offsetHeight = overlapsPeriods ? bookingHeight * 1.25 : 0;
        let periodStyle = { ...styles.period };

        if (now.getDay() === day) {
          const periodStartDate = new Date();
          periodStartDate.setHours(period.startHour);
          periodStartDate.setMinutes(period.startMinute);
          periodStartDate.setSeconds(0);

          const periodEndDate = new Date();
          periodEndDate.setHours(period.endHour);
          periodEndDate.setMinutes(period.endMinute);
          periodEndDate.setSeconds(0);
          // if (period.isBreak){
          //   periodStyle = {...periodStyle, ...styles.breakPeriod};
          // }
          if (
            now.getDay() === day &&
            periodStartDate < now &&
            periodEndDate > now &&
            new moment(props.startDate).format("dddd, LL") === new moment(new Date()).startOf("week").format("dddd, LL")
          ) {
            var difference =
              periodEndDate.getTime() - periodStartDate.getTime();
            var elapsed = periodEndDate.getTime() - now.getTime();
            var percentage = 100 - (elapsed * 100) / difference;
            periodStyle = {
              ...periodStyle,
              ...styles.now,
              backgroundPosition: `${percentage.toFixed(0)}%`,
            };
          }
        }
        periodDivs.push(
          <div
            data-period
            key={period.id}
            style={{
              ...periodStyle,
              minWidth: getWidth(period) + "px",
              width: getWidth(period) + "px",
              padding: cellPadding,
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              if (e.target.hasAttribute('data-period'))
                createNewBooking(day, period.order, false);
            }}
            onMouseEnter={(e) => {
              e.persist();
              mouseOverPeriod(e, day, period.order);
            }}
          >
            <div
              style={{ height: "40px", maxWidth: "100%", overflow: "hidden" }}
            >
              <small className="float-left">{period.name}<br/><small style={{fontSize:`0.8em`}}>{period.startTime} - {period.endTime}</small>
              </small>
            </div>
            <div style={{ position: "absolute", marginTop: 5 }}>
              {newBooking &&
                newBooking.dayNumber === day &&
                newBooking.startPeriod === period.order &&
                renderBooking(newBooking)}
            </div>
            <div
              style={{
                transition: "margin-top 0.1s",
                marginTop: 30,
              }}
            >
              {currentBookings.map((booking) => renderBooking(booking))}
            </div>
          </div>
        );
      });

    return (
      <div
        key={`pr_${day}`}
        className="d-flex"
        style={{
          minWidth: "fit-content",
        }}
      >
        {periodDivs}
      </div>
    );
  };

  return (
    <>
      <div
        style={styles.table}
        className="customScroll"
        ref={table}
      >
        {days.map(renderRow)}
      </div>
      <AlertModal show={showAlertModal} title={``} content={terms} confirmText={`I Agree`} onCancel={handleBookingCancel} onConfirm={handleBookingConfirm} />
    </>
  );
};

const styles = {
  table: {
    WebkitTouchCallout: "none",
    WebkitUserSelect: "none",
    KhtmlUserSelect: "none",
    MozUserSelect: "none",
    MsUserSelect: "none",
    userSelect: "none",
    maxWidth: "100vw",
    overflowX: "scroll",
    overflowY: "none",
    position: "relative",
  },
  tableBottom: {
    //borderBottom: "1px solid #c0c0c0",
  },
  booking: {
    borderRadius: 10,
    minHeight: "20px",
    padding: "10px 10px",
    color: '#fff',
    fontSize: 12,
    marginBottom: 5,
    cursor: "pointer",
    zIndex: 1,
    position: "relative",
    transition: "width 0.3s ease-in-out",
    maxHeight: 180,
    overflow: 'hidden'
  },
  now: {
    backgroundImage: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8DwHwAFBQIAX8jx0gAAAABJRU5ErkJggg==)",
    backgroundRepeat: "repeat-y",
  },
  periodHeader: {
    margin: "0 10px 10px 0",
    borderRadius: 6,
    backgroundColor: "#fff",
    minWidth: "60px",
    position: "sticky",
    padding: cellPadding,
    zIndex: 2,
    left: 0,
    minHeight: '160px',
    backgroundColor: "#c0c0c0",
  },
  period: {
    minHeight: "160px",
    margin: `0 ${cellPadding}px ${cellPadding}px 0`,
    borderRadius: 6,
    backgroundColor: "#e0e0e0",
    transition: 'all 0.2s ease-in-out'
  },
  breakPeriod: {
    backgroundColor: 'rgba(224, 224, 224, 0.8)'
  }
};
