/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
// reactstrap components
import { Collapse, Navbar, NavItem, NavLink, Nav, Container } from "reactstrap";

import "./Sidebar.scss";
var ps;

const Sidebar = (props) => {
  const [collapseOpen, setcollapseOpen] = useState(false);

  const headerLink = (icon, label, link) => {
    return (
      <>
        <NavItem key={link} data-text={label}>
          <NavLink
            tag={NavLinkRRD}
            to={link}
            className="cursor-pointer"
            style={styles.navLink}
            id={`tooltip_${icon}`}
          >
            <img
              style={styles.icon}
              src={require(`../../assets/img/icons/${icon}.svg`)}
            ></img>
          </NavLink>
        </NavItem>
      </>
    );
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  return (
    <Navbar
      className="sidebar-nav navbar-vertical fixed-left navbar-light bg-white pt-5"
      expand="md"
      id="sidenav-main"
      style={styles.navbar}
    >
      <Container fluid>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          <Nav navbar>
            {headerLink("user-calendar", "My Bookings", "/lab/bookings")}
            {headerLink("flask", "Lab Bookings", "/lab/bookings")}
            {headerLink("car", "Vehicle Bookings", "/lab/bookings")}
            {headerLink("school", "Resource Bookings", "/lab/bookings")}
            {headerLink("calendar", "Room Bookings", "/lab/bookings")}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

const styles = {
  navLink: { fontWeight: "bold" },
  icon: { maxWidth: 40, float: `left`, display: `block`, float: `left` },
  navbar: { overflowY: `visible`, zIndex: 1000 },
};

export default Sidebar;
